import styled from 'styled-components'

export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  z-index: 10;
  @media screen and (max-width: 1180px) {
    font-size: 24px;
  }
`
export const Container = styled.div`
  width: 100%;
  padding-top: 40px;
  position: relative;
`
export const TitleAndSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  z-index: 10;
`
export const ProjectsScrollWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 31px;
  overflow-x: auto;
  padding-bottom: 15px;
`
export const ProjectsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`
export const BlueBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-height: 489px;
  background: #f0f4f6;
`
export const NoActiveProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  z-index: 10;
  height: 425px;

  @media screen and (max-width: 767px) {
    height: 356px;
  }

  background: url("/images/no-active.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
export const NoActiveText = styled.div`
  color: #3c6;
  font-family: Gilroy;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
`
