import React from 'react'

const Shares = () => {
  return (
    <svg
      width="164"
      height="72"
      viewBox="0 0 164 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8389 34.3026L34.3104 34.3041C41.7221 34.3041 47.7304 40.3124 47.7304 47.7242H26.8517L26.8547 50.7064L50.7127 50.704V47.7242C50.7127 44.4962 49.7637 41.4522 48.0701 38.7751L56.6772 38.7775C62.6187 38.7775 67.7484 42.2527 70.1453 47.281C63.0929 56.5874 51.6727 62.6354 38.7837 62.6354C30.5489 62.6354 23.5729 60.8741 17.907 57.7898L17.9111 30.0425C21.6256 30.5745 25.0246 32.0844 27.8389 34.3026ZM14.9257 56.6709C14.9257 58.318 13.5905 59.6532 11.9435 59.6532H5.97901C4.33197 59.6532 2.99677 58.318 2.99677 56.6709V29.8307C2.99677 28.1837 4.33197 26.8485 5.97901 26.8485H11.9435C13.5905 26.8485 14.9257 28.1837 14.9257 29.8307V56.6709ZM53.6949 14.9196C58.6359 14.9196 62.6416 18.9251 62.6416 23.8663C62.6416 28.8074 58.6359 32.813 53.6949 32.813C48.7536 32.813 44.7482 28.8074 44.7482 23.8663C44.7482 18.9251 48.7536 14.9196 53.6949 14.9196ZM32.8192 5.97284C37.7602 5.97284 41.766 9.97841 41.766 14.9196C41.766 19.8607 37.7602 23.8663 32.8192 23.8663C27.878 23.8663 23.8725 19.8607 23.8725 14.9196C23.8725 9.97841 27.878 5.97284 32.8192 5.97284Z"
        fill="#33CC66"
      />
      <path
        d="M154.204 8.94672C155.851 8.94672 157.186 10.2819 157.186 11.929V59.6448C157.186 61.2919 155.851 62.6271 154.204 62.6271H100.523C98.8764 62.6271 97.5412 61.2919 97.5412 59.6448V11.929C97.5412 10.2819 98.8764 8.94672 100.523 8.94672H154.204ZM141.655 16.7637C138.64 16.7637 136.096 18.7793 135.294 21.5365H129.334L121.999 34.0142H117.711C116.909 31.2569 114.366 29.2413 111.35 29.2413C107.692 29.2413 104.725 32.2082 104.725 35.8671C104.725 39.5254 107.692 42.4919 111.35 42.4919C114.366 42.4919 116.909 40.4762 117.711 37.7197H122.098L129.306 50.0465H135.322C136.123 52.8027 138.667 54.8178 141.682 54.8178C145.342 54.8178 148.308 51.8516 148.308 48.1927C148.308 44.5338 145.342 41.5671 141.682 41.5671C138.667 41.5671 136.123 43.5831 135.322 46.3401H131.394L125.264 35.792L131.385 25.2417H135.294C136.096 27.9989 138.64 30.0145 141.655 30.0145C145.314 30.0145 148.281 27.0478 148.281 23.3893C148.281 19.7304 145.314 16.7637 141.655 16.7637ZM141.689 44.8779C143.608 44.8779 145.17 46.4397 145.17 48.3594C145.17 50.2794 143.608 51.84 141.689 51.84C140.001 51.84 138.591 50.6322 138.275 49.0361C138.275 49.0361 138.183 48.3612 138.275 47.6839C138.591 46.0867 140.001 44.8779 141.689 44.8779ZM111.412 32.3236C113.332 32.3236 114.893 33.8848 114.893 35.8048C114.893 37.7238 113.332 39.2856 111.412 39.2856C109.492 39.2856 107.931 37.7238 107.931 35.8048C107.931 33.8848 109.492 32.3236 111.412 32.3236ZM141.682 19.7694C143.602 19.7694 145.164 21.3304 145.164 23.2503C145.164 25.1694 143.602 26.7314 141.682 26.7314C139.995 26.7314 138.585 25.5231 138.269 23.9261C138.191 23.3118 138.269 22.5958 138.269 22.5958C138.585 20.9776 139.995 19.7694 141.682 19.7694Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Shares
