import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  max-width: 670px;
`
export const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`
export const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  min-height: 44px;
  border-radius: 4px;
  background: rgba(51, 204, 102, 0.15);
`
export const CheckMarkIcon = styled.div`
  min-width: 14px;
  min-height: 8px;
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`
export const CollapseText = styled.div<{ isActive: boolean }>`
  display: ${p => (p.isActive ? 'block' : 'none')};
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-left: 64px;
  padding-right: 32px;

  a {
    color: #3c6;
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding-right: 16px;
  }
`
