import React, { useRef, type ReactNode } from 'react'
import styles from './styles.module.scss'
interface ModalProps {
  children: ReactNode
  open?: boolean
}

export const Modal = ({ children }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  return (
    <div className={styles.modal} ref={modalRef}>
      {children}
    </div>
  )
}
