import styled from 'styled-components'

export const BannerWrap = styled.div`
  @media screen and (max-width: 1180px) {
    margin-top: 28px;
  }
`
export const BannerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 95px;

  @media screen and (max-width: 1180px) {
    gap: 22px;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
`
export const BannerText = styled.div`
  max-width: 720px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;

  @media screen and (max-width: 1180px) {
    max-width: 574px;
  }
`
export const BannerTitle = styled.div`
  color: #181833;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 1180px) {
    font-size: 32px;
  }

  span {
    color: #3c6;
  }
`
export const BannerDesc = styled.div`
  color: #181833;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`
export const BannerButtons = styled.div`
  display: flex;
  margin-top: 26px;
  gap: 16px;

  @media screen and (max-width: 1549px) {
    flex-wrap: wrap;
  }
`
export const BannerImg = styled.div`
  display: flex;
  position: relative;
  pointer-events: none;

  img {
    position: relative;
    top: 60px;
    left: 0;
  }

  @media screen and (max-width: 1180px) {
    img {
      top: 0;
    }
  }
  @media screen and (max-width: 767px) {
    img {
      width: 237.952px;
      height: 237.952px;
    }
  }
`
