import React from 'react'

const PriceTag = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M14.9042 6.10034L24.8037 7.51455L26.2179 17.4141L17.0255 26.6064C16.635 26.997 16.0019 26.997 15.6113 26.6064L5.71184 16.707C5.32131 16.3164 5.32131 15.6833 5.71184 15.2927L14.9042 6.10034ZM15.6113 8.22166L7.83316 15.9998L16.3184 24.4851L24.0966 16.707L23.036 9.28232L15.6113 8.22166ZM17.7327 14.5856C16.9516 13.8046 16.9516 12.5382 17.7327 11.7572C18.5137 10.9762 19.78 10.9762 20.5611 11.7572C21.3421 12.5382 21.3421 13.8046 20.5611 14.5856C19.78 15.3667 18.5137 15.3667 17.7327 14.5856Z"
        fill="white"
      />
    </svg>
  )
}

export default PriceTag
