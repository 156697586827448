import { walletConnect } from 'wagmi/connectors'
import { createConfig, http } from 'wagmi'
import { bsc } from 'viem/chains'
export const config = createConfig({
  chains: [bsc],
  connectors: [
    walletConnect({
      projectId: '0fdebba0250b08c32618bc244f63fe38',
      showQrModal: true,
    }),
  ],
  transports: {
    [bsc.id]: http(),
  },
})
