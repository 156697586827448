import React from 'react'
import { StyledSpinner } from './styled'

interface LoaderProps {
  animationDuration?: number
  size?: number
  color?: string
}

const Loader = (props: LoaderProps) => {
  return (
    <StyledSpinner viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </StyledSpinner>
  )
}
export default Loader
