import React from 'react'

const Team = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="white" />
      <path
        d="M20 18C22.2091 18 24 16.2091 24 14C24 11.7909 22.2091 10 20 10C17.7909 10 16 11.7909 16 14C16 16.2091 17.7909 18 20 18ZM13.5 21C14.8807 21 16 19.8807 16 18.5C16 17.1193 14.8807 16 13.5 16C12.1193 16 11 17.1193 11 18.5C11 19.8807 12.1193 21 13.5 21ZM29 18.5C29 19.8807 27.8807 21 26.5 21C25.1193 21 24 19.8807 24 18.5C24 17.1193 25.1193 16 26.5 16C27.8807 16 29 17.1193 29 18.5ZM20 19C22.7614 19 25 21.2386 25 24V30H15V24C15 21.2386 17.2386 19 20 19ZM13 23.9999C13 23.307 13.1007 22.6376 13.2882 22.0056L13.1186 22.0204C11.365 22.2104 10 23.6958 10 25.4999V29.9999H13V23.9999ZM30 29.9999V25.4999C30 23.6378 28.5459 22.1153 26.7118 22.0056C26.8993 22.6376 27 23.307 27 23.9999V29.9999H30Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Team
