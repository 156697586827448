import React from 'react'

const Accessibility = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#33CC66" />
      <path
        d="M8.74756 31.25V28.75H11.2476V10C11.2476 9.30965 11.8072 8.75 12.4976 8.75H27.4975C28.1879 8.75 28.7475 9.30965 28.7475 10V28.75H31.2475V31.25H8.74756ZM23.7475 18.75H21.2475V21.25H23.7475V18.75Z"
        fill="white"
      />
    </svg>
  )
}

export default Accessibility
