import React from 'react'
import {
  Wrapper,
  Title,
  TitleWrapper,
  SubTitle,
  TextsWrapper,
  ButtonContentWrapper,
  ButtonText,
  CollapseWrapper,
  FaqText,
  CirclesImage,
} from './styled'
import Button from '../../../../components/UIKit/Button/Button'
import QuestionIcon from './Assets/Question'
import Collapse from './Collapse'

const FAQ = () => {
  return (
    <div className={'_container'}>
      <Wrapper>
        <TitleWrapper>
          <TextsWrapper>
            <Title>Need help?</Title>
            <SubTitle>Learn how it works</SubTitle>
          </TextsWrapper>
          <Button type={'base'} link={'/guide.pdf'}>
            <ButtonContentWrapper>
              <QuestionIcon />
              <ButtonText>How it works</ButtonText>
            </ButtonContentWrapper>
          </Button>
        </TitleWrapper>
        <CollapseWrapper>
          <FaqText>FAQ</FaqText>
          <Collapse />
        </CollapseWrapper>
        <CirclesImage src={'/images/circles.svg'} />
      </Wrapper>
    </div>
  )
}

export default FAQ
