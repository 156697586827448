import React from 'react'
import styles from './styles.module.scss'

import Img1 from './Assets/img1.png'
import Img2 from './Assets/img2.png'
import Img3 from './Assets/img3.png'
import Img4 from './Assets/img4.png'
import Img5 from './Assets/img5.png'
import Img6 from './Assets/img6.png'

const UtilitiesImg = () => {
  return (
    <div className={styles.utilitiesImg}>
      <img src={Img1} alt="img" />
      <img src={Img2} alt="img" />
      <img src={Img3} alt="img" />
      <img src={Img4} alt="img" />
      <img src={Img5} alt="img" />
      <img src={Img6} alt="img" />
    </div>
  )
}

export default UtilitiesImg
