import React, { ChangeEvent, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useAccount, useReadContract, useWriteContract } from 'wagmi'

import {
  Heading,
  SubscriptionWrapper,
  Wrapper,
  RowsWrapper,
  RowWrapper,
  RowImg,
  RowText,
  RowValue,
  InputWrapper,
  Input,
  Button,
  ButtonDeposit,
  ExtraInfo,
} from './styled'

import MMProIcon from '../../Assets/mmpro.svg'
import DepositIcon from '../../Assets/deposit.svg'

import Timer from '../Common/Timer'
import Loader from '../../../../components/UIKit/Loader'

import {
  getNumberFromBigInt,
  DEFAULT_TOKEN_DECIMAL,
} from '../../../../utils/formatBigInt'
import { useMmproBalance } from '../../../../hooks/useBalance'
import { contractsAddresses } from '../../../../config/contractsAddresses'
import { MmproAbi } from '../../../../contracts/Mmpro'
import { MAX_ALLOWANCE } from '../../../../config/allowance'
import { showNegativeToast, showPositiveToast } from '../../../../utils/toasts'
import { ProjectType } from '../../../../types/project'
import { MainContractAbi } from '../../../../contracts/MainContract'
import { useUserInfo } from '../../../../hooks/useUserInfo'
import { getTransactionReceipt } from '../../../../utils/getTransacationReceipt'
import { usePoolInfo } from '../../../../hooks/usePoolInfo'

type MMProPayment = {
  project: ProjectType
}

const SubscriptionEndedIn = ({ project }: MMProPayment) => {
  const { address } = useAccount()
  const mmproBalance = useMmproBalance()
  const { writeContract } = useWriteContract()
  const { totalDeposit = BigInt(0), response: userInfoResponse } = useUserInfo(project.id)
  const { depositLimit = BigInt(0) } = usePoolInfo(project.id)

  const [depositValue, setDepositValue] = useState('')
  const [isTxLoading, setIsTxLoading] = useState(false)
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)

  const { data: allowance, refetch: allowanceRefetch } = useReadContract({
    abi: MmproAbi,
    address: contractsAddresses.mmpro,
    functionName: 'allowance',
    args: [address || '0x1', contractsAddresses.main],
  })

  const userDepositLimit = project.limitSale
    ? getNumberFromBigInt(depositLimit - totalDeposit).toFixed(1)
    : getNumberFromBigInt(mmproBalance).toFixed(1)
  const handleDepositChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDepositValue(e.target.value)

    if (timerId) {
      clearTimeout(timerId)
    }

    const newTimerId = setTimeout(() => {
      if (Number(e.target.value) > Number(userDepositLimit)) {
        showNegativeToast('Insufficient balance!', true)
      }
    }, 500)

    setTimerId(newTimerId)
  }
  const handleMaxClick = () => {
    setDepositValue(userDepositLimit)
  }

  const deposit = () => {
    if (Number(depositValue) > Number(userDepositLimit)) return
    setIsTxLoading(true)

    const weiDepositValue = new BigNumber(depositValue)
      .times(DEFAULT_TOKEN_DECIMAL)
      .toString()

    writeContract(
      {
        abi: MainContractAbi,
        address: contractsAddresses.main,
        functionName: 'deposit',
        args: [BigInt(project.id), BigInt(weiDepositValue)],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status == 'success') {
            userInfoResponse.refetch()
            setIsTxLoading(false)
            setDepositValue('')
            showPositiveToast('Deposit was successfully!')
          }
        },
        onError: error => {
          showNegativeToast(error.message)
          setDepositValue('')
          setIsTxLoading(false)
        },
      },
    )
  }
  const approve = () => {
    setIsTxLoading(true)
    writeContract(
      {
        abi: MmproAbi,
        address: contractsAddresses.mmpro,
        functionName: 'approve',
        args: [contractsAddresses.main, MAX_ALLOWANCE],
        account: address || '0x1',
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status == 'success') {
            allowanceRefetch()
            setIsTxLoading(false)
            showPositiveToast('Transaction was successfully')
            setDepositValue('')
          }
        },
        onError: error => {
          showNegativeToast(error.message)
          setIsTxLoading(false)
        },
      },
    )
  }

  const isApprove = typeof allowance === 'bigint' && allowance <= BigInt(0)

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [timerId])

  return (
    <Wrapper>
      <SubscriptionWrapper>
        <Heading>Subscription ends in</Heading>
        <Timer dateInSeconds={project.subEndTime} />
        <RowsWrapper>
          <RowWrapper>
            <RowImg src={MMProIcon} alt="icon" />
            <RowText>MMPro Balance</RowText>
            <RowValue>{getNumberFromBigInt(mmproBalance).toFixed(1)}</RowValue>
          </RowWrapper>
          <RowWrapper>
            <RowImg src={DepositIcon} alt="icon" />
            <RowText>MMPro Deposit</RowText>
            <RowValue>{getNumberFromBigInt(totalDeposit).toFixed(1)}</RowValue>
          </RowWrapper>
        </RowsWrapper>
        <InputWrapper>
          <Input type="number" onChange={handleDepositChange} value={depositValue} />
          <Button onClick={handleMaxClick}>Max</Button>
        </InputWrapper>
        <ButtonDeposit
          isDisabled={
            !(depositValue && address) ||
            isTxLoading ||
            Number(depositValue) > Number(userDepositLimit)
          }
          onClick={isApprove ? approve : deposit}
        >
          {isTxLoading ? <Loader /> : isApprove ? 'Approve' : 'Deposit'}
        </ButtonDeposit>
        <ExtraInfo>Deposit your MMPro for launchpad allocation</ExtraInfo>
        {project.limitSale && (
          <ExtraInfo>Your MMPRO deposit limit: {userDepositLimit}</ExtraInfo>
        )}
      </SubscriptionWrapper>
    </Wrapper>
  )
}

export default SubscriptionEndedIn
