import Experience from './Assets/Experience'
import Influencers from './Assets/Influencers'
import Support from './Assets/Support'
import Team from './Assets/Team'
import Trust from './Assets/Trust'
export const benefitsCards = [
  {
    id: 1,
    icon: Support,
    title: '24/7 Online Support',
    subtitle:
      'Our team of experts is available round the clock to answer your questions and provide support.',
  },
  {
    id: 2,
    icon: Experience,
    title: 'Over 7 Years of Experience in the Market',
    subtitle:
      'With more than seven years of experience in the financial markets, we have an unparalleled understanding of them.',
  },
  {
    id: 3,
    icon: Trust,
    title: 'Our Own Investment Trust, MMRPO TRUST',
    subtitle:
      'We manage our own investment fund, ensuring reliability and transparency for our investors.',
  },
  {
    id: 4,
    icon: Team,
    title: 'Dedicated Market Maker Team',
    subtitle:
      'Our team of professional traders creates liquidity and ensures market stability.',
  },
  {
    id: 5,
    icon: Influencers,
    title: 'A Network of Over 1000 Influencers',
    subtitle:
      'We have a wide network of influential individuals capable of supporting and promoting our projects in the market.',
  },
]
