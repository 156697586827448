import styled from 'styled-components'

export const TokenWrap = styled.div`
  margin-top: 70px;
  width: 100%;
  background-color: #f5f8fa;
  height: 100%;

  @media screen and (max-width: 1180px) {
    margin-top: 41px;
    height: auto;
  }
`
export const TokenContainer = styled.div`
  position: relative; 
  padding-bottom: 34px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 460px;
    background: url("/images/candle.svg") no-repeat;
    background-size: cover;
    pointer-events: none;
    z-index: 0;
  }
`
export const TokenTitle = styled.div`
  position: relative;
  z-index: 10;

  padding-top: 47px;
  color: #181833;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media screen and (max-width: 1180px) {
    font-size: 24px;
  }
`
export const TokenExchanges = styled.div`
  position: relative;
  z-index: 10;

  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;

  @media screen and (max-width: 1023px) {
    justify-content: center;
  }
`
export const Exchange = styled.a`
  min-width: 440px;
  flex: 1;

  @media screen and (max-width: 1023px) {
    min-width: 300px;
  }
`
export const ExchangeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
  border-radius: 6px 6px 0px 0px;
  background: #fff;
`

interface ExchangeBottomProps {
  bgColor?: string | undefined
}
export const ExchangeBottom = styled.div<ExchangeBottomProps>`
  height: 10px;
  border-radius: 0px 0px 6px 6px;
  background: ${props => props.bgColor};
`
