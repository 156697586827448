import React from 'react'

const UtilitiesLaunchpad = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 7V11H7V7H11ZM13 7H18V11H13V7ZM11 18H7V13H11V18ZM13 18V13H18V17C18 17.5523 17.5523 18 17 18H13ZM11 0V5H7V0H11ZM13 0H17C17.5523 0 18 0.44772 18 1V5H13V0ZM5 7V11H0V7H5ZM5 18H1C0.44772 18 0 17.5523 0 17V13H5V18ZM5 0V5H0V1C0 0.44772 0.44772 0 1 0H5Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default UtilitiesLaunchpad
