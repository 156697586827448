import { type ExchangeType } from './type'

export const exchanges: ExchangeType[] = [
  {
    imgSrc: '/images/exchanges/gate-io.svg',
    bgColor: '#2354e6',
    link: 'https://www.gate.io/ru/trade/MMPRO_USDT',
  },
  {
    imgSrc: '/images/exchanges/pancake-swap.svg',
    bgColor: '#D1884F',
    link: 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x6067490d05F3cF2fdFFC0e353b1f5Fd6E5CCDF70',
  },
  {
    imgSrc: '/images/exchanges/azbit.svg',
    bgColor: '#18C28E',
    link: 'https://azbit.com/exchange/MMPRO_USDT',
  },
  {
    imgSrc: '/images/exchanges/kanga-exchange.svg',
    bgColor: '#E65321',
    link: 'https://trade.kanga.exchange/market/MMPRO-USDT',
  },
]
