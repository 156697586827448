import React from 'react'

const Support = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="white" />
      <path
        d="M20.0061 21.2535C22.0771 21.2535 23.7561 22.9325 23.7561 25.0035C23.7561 26.0649 23.3151 27.0234 22.6064 27.7056L20.2187 30.003L23.7561 30.0035V32.5035H16.2561L16.255 30.348L20.8729 25.9042C21.1092 25.6764 21.2561 25.3571 21.2561 25.0035C21.2561 24.3131 20.6965 23.7535 20.0061 23.7535C19.3157 23.7535 18.7561 24.3131 18.7561 25.0035H16.2561C16.2561 22.9325 17.935 21.2535 20.0061 21.2535ZM27.5061 21.2535V26.2535H30.0061V21.2535H32.5061V32.5035H30.0061V28.7535H25.0061V21.2535H27.5061Z"
        fill="#33CC66"
      />
      <path
        d="M33.75 17.5C33.75 16.1193 32.6308 15 31.25 15H29.9226C29.3075 10.0671 25.0995 6.25 20 6.25C14.9005 6.25 10.6925 10.0671 10.0774 15H8.75C7.36929 15 6.25 16.1193 6.25 17.5V22.5C6.25 23.8808 7.36929 25 8.75 25H12.5V16.25C12.5 12.1079 15.8579 8.75 20 8.75C24.1421 8.75 27.5 12.1079 27.5 16.25V17.5H33.75Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Support
