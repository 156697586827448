import React from 'react'

const Trust = () => {
  return (
    <svg
      width="60"
      height="40"
      viewBox="0 0 60 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="40" rx="4" fill="white" />
      <path
        d="M7.26 13H17.56V15.64H13.78V27H11.02V15.64H7.26V13ZM20.2913 17V18.72C20.5179 18.0933 20.9046 17.62 21.4513 17.3C21.9979 16.9667 22.6246 16.8 23.3313 16.8V19.68C22.5446 19.5733 21.8379 19.7333 21.2113 20.16C20.5979 20.5733 20.2913 21.26 20.2913 22.22V27H17.7113V17H20.2913ZM31.4402 22.4V17H34.0202V27H31.4402V25.88C30.8268 26.8133 29.8335 27.28 28.4602 27.28C27.3668 27.28 26.4602 26.9133 25.7402 26.18C25.0335 25.4333 24.6802 24.42 24.6802 23.14V17H27.2602V22.82C27.2602 23.4733 27.4335 23.98 27.7802 24.34C28.1402 24.7 28.6268 24.88 29.2402 24.88C29.9202 24.88 30.4535 24.6733 30.8402 24.26C31.2402 23.8333 31.4402 23.2133 31.4402 22.4ZM38.685 19.84C38.685 20.2667 39.2917 20.6333 40.505 20.94C40.9317 21.0333 41.3183 21.1467 41.665 21.28C42.0117 21.4 42.3583 21.58 42.705 21.82C43.065 22.0467 43.345 22.3533 43.545 22.74C43.745 23.1267 43.845 23.5733 43.845 24.08C43.845 25.1067 43.4583 25.9 42.685 26.46C41.9117 27.0067 40.9583 27.28 39.825 27.28C37.7717 27.28 36.3717 26.4867 35.625 24.9L37.865 23.64C38.1717 24.5067 38.825 24.94 39.825 24.94C40.745 24.94 41.205 24.6533 41.205 24.08C41.205 23.6533 40.5983 23.2867 39.385 22.98C38.9317 22.86 38.545 22.74 38.225 22.62C37.905 22.5 37.5583 22.3267 37.185 22.1C36.8117 21.86 36.525 21.56 36.325 21.2C36.1383 20.8267 36.045 20.3933 36.045 19.9C36.045 18.9133 36.405 18.14 37.125 17.58C37.8583 17.0067 38.765 16.72 39.845 16.72C40.6583 16.72 41.3983 16.9067 42.065 17.28C42.7317 17.64 43.2583 18.16 43.645 18.84L41.445 20.04C41.125 19.36 40.5917 19.02 39.845 19.02C39.5117 19.02 39.2317 19.1 39.005 19.26C38.7917 19.4067 38.685 19.6 38.685 19.84ZM50.9313 17V19.48H48.6713V23.64C48.6713 23.9867 48.7579 24.24 48.9313 24.4C49.1046 24.56 49.3579 24.6533 49.6913 24.68C50.0246 24.6933 50.4379 24.6867 50.9313 24.66V27C49.1846 27.2 47.9379 27.04 47.1913 26.52C46.4579 25.9867 46.0913 25.0267 46.0913 23.64V19.48H44.3513V17H46.0913V14.98L48.6713 14.2V17H50.9313Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Trust
