import React from 'react'

const UtilitiesFarming = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0049 8.00288V10.0029C22.0049 13.3167 17.08 16.0029 11.0049 16.0029C5.03824 16.0029 0.181143 13.4117 0.00957286 10.1799L0.00488281 10.0029V8.00288C0.00488281 11.3167 4.92975 14.0029 11.0049 14.0029C17.08 14.0029 22.0049 11.3167 22.0049 8.00288ZM11.0049 0.00292969C17.08 0.00292969 22.0049 2.68922 22.0049 6.00288C22.0049 9.31668 17.08 12.0029 11.0049 12.0029C4.92975 12.0029 0.00488281 9.31668 0.00488281 6.00288C0.00488281 2.68922 4.92975 0.00292969 11.0049 0.00292969Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default UtilitiesFarming
