import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 530px;
  z-index: 10;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    width: 280px;
  }

  @media screen and (max-width: 768px) {
    min-width: 280px;
    width: 100%;
  }
`
export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 30px 30px 16px 30px;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  min-height: 212px;
  
  @media screen and (max-width: 768px) {
    min-height: 218px;
  }

  @media screen and (max-width: 768px) {
    min-width: 280px;
    width: 100%;
  }
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
`
export const Subscription = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  opacity: 0.6;

  @media screen and (max-width: 1023px) {
    font-size: 14px;
  }
`
export const TitleAndSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const Description = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 100%;

  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
`
export const Logo = styled.img`
  width: 84px;
  height: 84px;
  border-radius: 4px;

  @media screen and (max-width: 1023px) {
    width: 70px;
    height: 70px;
  }
`
export const TitleAngLogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`
export const CardsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  background: radial-gradient(
    706.13% 141.42% at 100% 0%,
    #33cc9e 0%,
    #3c6 100%
  );
  border-radius: 0 0 20px 20px;
  padding: 30px;
  width: 530px;

  @media screen and (max-width: 1023px) {
    width: 280px;
    padding: 20px;
    gap: 10px;
  }

  @media screen and (max-width: 768px) {
    min-width: 280px;
    width: 100%;
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 17px;
  width: 100%;

  @media screen and (max-width: 1023px) {
    gap: 10px;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 68px;
  border-radius: 8px;
  background: #fff;
  padding: 11px 14px;
  width: 100%;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    width: 145px;
  }

  &:nth-child(4),
  &:nth-child(5) {
    width: 226px;
  }

  @media screen and (max-width: 1023px) {
    &:nth-child(2),
    &:nth-child(3) {
      width: 115px;

      @media screen and (max-width: 768px) {
        min-width: 115px;
        width: 100%;
      }
    }

    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(5) {
      width: 100%;
    }
  }
`
export const CardTitle = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  opacity: 0.5;
`
export const CardValue = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`
