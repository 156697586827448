import React, { useEffect, useState } from 'react'
import { DateText, DateWrapper } from './styled'

type TimerProps = {
  dateInSeconds: number
}

const Timer = ({ dateInSeconds }: TimerProps) => {
  const [timer, setTimer] = useState({
    days: 0,
    hours: '0',
    minutes: '0',
    seconds: '0',
  })

  useEffect(() => {
    const updateCountdown = () => {
      const currentTime = Math.floor(Date.now() / 1000)
      const timeDifference = dateInSeconds - currentTime

      if (timeDifference >= 0) {
        const days = Math.floor(timeDifference / (3600 * 24))
        const hours = Math.floor((timeDifference % (3600 * 24)) / 3600)
          .toString()
          .padStart(2, '0')
        const minutes = Math.floor((timeDifference % 3600) / 60)
          .toString()
          .padStart(2, '0')
        const seconds = Math.floor(timeDifference % 60)
          .toString()
          .padStart(2, '0')

        setTimer({ days, hours, minutes, seconds })
      } else {
        setTimer({ days: 0, hours: '0', minutes: '0', seconds: '0' })
      }
    }

    const timerId = setInterval(updateCountdown, 1000)

    return () => clearInterval(timerId)
  }, [dateInSeconds])

  return (
    <DateWrapper>
      <DateText>
        <span>{timer.days}</span> days
      </DateText>
      <DateText>
        <span>{timer.hours}</span> hours
      </DateText>
      <DateText>
        <span>{timer.minutes}</span> min
      </DateText>
      <DateText>
        <span>{timer.seconds}</span> secs
      </DateText>
    </DateWrapper>
  )
}

export default Timer
