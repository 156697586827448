import React from 'react'

const Experience = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="white" />
      <path
        d="M15.26 15.64V13H24.3V15.34L19.56 27H16.64L21.24 15.64H15.26Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Experience
