import { useGenericReadContract } from './useGenericReadContract'
import { MmproAbi } from '../contracts/Mmpro'
import { contractsAddresses } from '../config/contractsAddresses'
import { useAccount } from 'wagmi'
import { UsdtAbi } from '../contracts/Usdt'

export const useMmproBalance = () => {
  const { address } = useAccount()

  const { data } = useGenericReadContract(
    MmproAbi,
    contractsAddresses.mmpro,
    'balanceOf',
    [address || '0x1'],
  )

  return data
}

export const useUsdtBalance = () => {
  const { address } = useAccount()

  const { data } = useGenericReadContract(UsdtAbi, contractsAddresses.usdt, 'balanceOf', [
    address || '0x1',
  ])

  return data
}
