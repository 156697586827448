import {
  AboutWrap,
  AboutContainer,
  AboutTitle,
  AboutDescription,
  Description,
  CorporateElement,
  Corporations,
  LabelCorporate,
  Img,
  Icons,
  Feature,
  Features,
  FeatureText,
  Year,
  InfoIconWrapper,
  BgEllipseRight,
  BgEllipseLeft,
} from './styled'
import { corporations } from './data'
import InfoIcon from '../../assets/Info'
import Tooltip from '../UIKit/Tooltip'
import React from 'react'

const corporateElements = corporations?.map(el => {
  return (
    <CorporateElement key={el.id}>
      <Year>{el.year}</Year>
      <Img>
        <img src={el.imgSrc} alt="corporate" />
      </Img>
      <Description>
        {el.desc}
        <Tooltip title={el.tooltipText}>
          <InfoIconWrapper>
            <InfoIcon />
          </InfoIconWrapper>
        </Tooltip>
      </Description>
      <Icons>
        <a href={el.global}>
          <img src="/images/global-line.svg" alt="link" />
        </a>
        <a href={el.twitter}>
          <img src="/images/twitter.svg" alt="link" />
        </a>
        <a href={el.tg}>
          <img src="/images/tg.svg" alt="link" />
        </a>
      </Icons>
      <Features>
        {el.features.map((el, index) => {
          return (
            <Feature key={el}>
              <FeatureText key={index}>{el}</FeatureText>
            </Feature>
          )
        })}
      </Features>
    </CorporateElement>
  )
})
const About = () => {
  return (
    <AboutWrap>
      {/* <BgEllipseLeft /> */}
      {/* <BgEllipseRight /> */}
      <div className={'_container'}>
        <AboutContainer>
          <AboutTitle>About MMPro</AboutTitle>
          <AboutDescription>
            Find out more about <span>MMPRO companies</span> and{' '}
            <span>our capabilities</span>
          </AboutDescription>
          <LabelCorporate>MMPRO Group - Corporate Structure</LabelCorporate>
          <Corporations>{corporateElements}</Corporations>
        </AboutContainer>
      </div>
    </AboutWrap>
  )
}

export default About
