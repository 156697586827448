import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 45px
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`
