import React, { useState } from 'react'
import { exchanges } from './data'
import {
  Exchange,
  ExchangeBottom,
  ExchangeContent,
  TokenContainer,
  TokenExchanges,
  TokenTitle,
  TokenWrap,
} from './styled'
import Button from '../../../../components/UIKit/Button/Button'

export const Token = () => {
  const [more, setMore] = useState(false)
  const indexByLeng = more ? exchanges.length : 2

  return (
    <TokenWrap>
      <div className="_container">
        <TokenContainer>
          <TokenTitle>Token available on exchanges</TokenTitle>
          <TokenExchanges>
            {exchanges?.slice(0, indexByLeng).map((el, index) => {
              return (
                <Exchange key={index} href={el.link} target="_blank">
                  <ExchangeContent>
                    <img src={el.imgSrc} alt="exchange" />
                  </ExchangeContent>
                  <ExchangeBottom bgColor={el.bgColor} />
                </Exchange>
              )
            })}
          </TokenExchanges>
          {!more ? (
            <Button
              type={'second'}
              onClick={() => setMore(true)}
              styles={{ marginTop: '19px', background: '#fff' }}
            >
              All Exchanges
            </Button>
          ) : (
            <Button
              type={'second'}
              onClick={() => setMore(false)}
              styles={{ marginTop: '19px', background: '#fff' }}
            >
              Hide
            </Button>
          )}
        </TokenContainer>
      </div>
    </TokenWrap>
  )
}

export default Token
