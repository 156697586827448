import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Heading,
  Wrapper,
  InputWrapper,
  Input,
  Button,
  ButtonDeposit,
  ExtraInfo,
  Tab,
  TabText,
  TabsWrapper,
  TabImg,
  RowsWrapper,
  RowWrapper,
  RowImg,
  RowText,
  RowValue,
} from './styled'

import Timer from '../Common/Timer'

import PriceIcon from '../../Assets/Price.png'
import { ProjectType } from '../../../../types/project'
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import { contractsAddresses } from '../../../../config/contractsAddresses'
import BigNumber from 'bignumber.js'
import {
  DEFAULT_TOKEN_DECIMAL,
  getFullDisplayBalance,
  getNumberFromBigInt,
} from '../../../../utils/formatBigInt'
import { MainContractAbi } from '../../../../contracts/MainContract'
import { showNegativeToast, showPositiveToast } from '../../../../utils/toasts'
import { MAX_ALLOWANCE } from '../../../../config/allowance'
import Loader from '../../../../components/UIKit/Loader'
import { useUserInfo } from '../../../../hooks/useUserInfo'
import { getTransactionReceipt } from '../../../../utils/getTransacationReceipt'
import { stableCoins } from '../../../../config/stableCoins'
import { usePoolInfo } from '../../../../hooks/usePoolInfo'

type SalesEndIn = {
  project: ProjectType
}
const SalesEndIn = ({ project }: SalesEndIn) => {
  const { address } = useAccount()
  const { writeContract } = useWriteContract()
  const { purchasedAmount = BigInt(0), response: userInfoResponse } = useUserInfo(
    project.id,
  )
  const { ITokenPrice = BigInt(0) } = usePoolInfo(project.id)

  const [activeTab, setActiveTab] = useState(stableCoins[0].id)
  const [isTxLoading, setIsTxLoading] = useState(false)
  const [tokensCount, setTokensCount] = useState('')
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)

  const activeStableCoin = stableCoins[activeTab - 1]

  const { data: stableCoinAllowance, refetch: stableCoinAllowanceRefetch } =
    useReadContract({
      abi: activeStableCoin.abi,
      address: activeStableCoin.contractAddress,
      functionName: 'allowance',
      args: [address || '0x1', contractsAddresses.main],
    })

  const { data: stableCoinBalance, refetch: stableCoinBalanceRefetch } = useReadContract({
    abi: activeStableCoin.abi,
    address: activeStableCoin.contractAddress,
    functionName: 'balanceOf',
    args: [address || '0x1'],
  })

  const { data: pendingAllocation } = useReadContract({
    abi: MainContractAbi,
    address: contractsAddresses.main,
    functionName: 'pendingAllocation',
    args: [BigInt(project.id), address || '0x1'],
  })

  const maxTokensCount = getNumberFromBigInt(pendingAllocation)

  const allocatedInUSD =
    getNumberFromBigInt(pendingAllocation) * getNumberFromBigInt(ITokenPrice) || 0

  const isApprove = getNumberFromBigInt(stableCoinAllowance) <= 0
  const handleActiveTab = (tab: number) => {
    setActiveTab(tab)
    setTokensCount('')
  }
  const handleMaxClick = () => {
    if (!stableCoinBalance) return
    const maxTokenCountByBalance = new BigNumber(stableCoinBalance.toString()).dividedBy(
      project.price,
    )

    const max =
      maxTokensCount <= getNumberFromBigInt(stableCoinBalance) / project.price
        ? maxTokensCount
        : getFullDisplayBalance(maxTokenCountByBalance)

    setTokensCount(max.toString())
  }
  const handleTokensCountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTokensCount(e.target.value)

    if (timerId) {
      clearTimeout(timerId)
    }

    const newTimerId = setTimeout(() => {
      if (Number(e.target.value) > maxTokensCount) {
        showNegativeToast('The limit of available tokens has been exceeded!', true)
      }
    }, 500)

    setTimerId(newTimerId)
  }
  const buyToken = () => {
    if (Number(tokensCount) > maxTokensCount) return
    setIsTxLoading(true)

    const weiTokensCount = new BigNumber(tokensCount)
      .times(DEFAULT_TOKEN_DECIMAL)
      .toString()

    writeContract(
      {
        abi: MainContractAbi,
        address: contractsAddresses.main,
        functionName: 'buyToken',
        args: [BigInt(project.id), BigInt(weiTokensCount), BigInt(2)],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status === 'success') {
            userInfoResponse.refetch()
            stableCoinBalanceRefetch()
            setIsTxLoading(false)
            showPositiveToast('Tokens purchased successfully!')
            setTokensCount('')
          }
        },
        onError: error => {
          showNegativeToast(error.message)
          setIsTxLoading(false)
          setTokensCount('')
        },
      },
    )
  }
  const approve = () => {
    setIsTxLoading(true)
    writeContract(
      {
        abi: activeStableCoin.abi,
        address: activeStableCoin.contractAddress,
        functionName: 'approve',
        args: [contractsAddresses.main, MAX_ALLOWANCE],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status === 'success') {
            stableCoinAllowanceRefetch()
            setIsTxLoading(false)
            showPositiveToast('Transaction was successfully')
          }
        },
        onError: error => {
          showNegativeToast(error.message)
          setIsTxLoading(false)
        },
      },
    )
  }

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [timerId])

  return (
    <Wrapper>
      <Heading>Sales ends in</Heading>
      <Timer dateInSeconds={project.saleClose} />
      {getNumberFromBigInt(pendingAllocation) > 0 && (
        <>
          <TabsWrapper>
            {stableCoins.map(coin => (
              <Tab
                key={coin.id}
                isActive={activeTab === coin.id}
                onClick={() => handleActiveTab(coin.id)}
              >
                <TabImg src={coin.img} />
                <TabText>{coin.name}</TabText>
              </Tab>
            ))}
          </TabsWrapper>
          <RowsWrapper>
            <RowWrapper>
              <RowImg src={activeStableCoin.img} alt="icon" />
              <RowText>{activeStableCoin.name} Balance</RowText>
              <RowValue>{getNumberFromBigInt(stableCoinBalance).toFixed(1)}</RowValue>
            </RowWrapper>
            <RowWrapper>
              <RowImg src={PriceIcon} alt="icon" />
              <RowText>Price in USD</RowText>
              <RowValue>
                {(project.price_per_share * Number(tokensCount)).toFixed(1)}
              </RowValue>
            </RowWrapper>
          </RowsWrapper>
          <InputWrapper>
            <Input
              type="number"
              value={tokensCount}
              onChange={handleTokensCountChange}
              placeholder={`Enter amount of ${project.tokenName} token`}
            />
            <Button onClick={handleMaxClick}>Max</Button>
          </InputWrapper>
          <ButtonDeposit
            onClick={isApprove ? approve : buyToken}
            isDisabled={
              !(tokensCount && address) ||
              isTxLoading ||
              Number(tokensCount) > maxTokensCount
            }
          >
            {isTxLoading ? (
              <Loader />
            ) : isApprove ? (
              'Approve'
            ) : (
              `Buy token for ${activeStableCoin.name}`
            )}
          </ButtonDeposit>
          <ExtraInfo>
            Your allocation: {getNumberFromBigInt(pendingAllocation).toFixed(1)}{' '}
            {project.tokenName} ({allocatedInUSD.toFixed(1)} USD)
          </ExtraInfo>
          {+getNumberFromBigInt(purchasedAmount) > 0 && (
            <ExtraInfo>
              Purchased: {getNumberFromBigInt(purchasedAmount).toFixed(1)}{' '}
              {project.tokenName}
            </ExtraInfo>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default SalesEndIn
