import { Link } from 'react-scroll'
import Button from '../../../../components/UIKit/Button/Button'
import {
  BannerWrap,
  BannerContainer,
  BannerDesc,
  BannerImg,
  BannerInfo,
  BannerTitle,
  BannerText,
  BannerButtons,
} from './styled'
import React from 'react'

const MainBanner = () => {
  return (
    <BannerWrap>
      <div className="_container">
        <BannerContainer>
          <BannerInfo>
            <BannerText>
              <BannerTitle>
                <span>MMPRO Launchpad – </span> money maker for crypto projects, investors
                and KOLs
              </BannerTitle>
              <BannerDesc>
                Use $MMPRO tokens to get early-access to top-tier private sales and IDOs
              </BannerDesc>
            </BannerText>
            <BannerButtons>
              <Button
                type={'base'}
                link={
                  'https://pancakeswap.finance/swap?inputCurrency=0x6067490d05F3cF2fdFFC0e353b1f5Fd6E5CCDF70&outputCurrency=0x55d398326f99059fF775485246999027B3197955'
                }
              >
                <img src="/images/logo-btn.svg" alt="logo-btn" />
                Buy MMPro Token
              </Button>
              <Link to={'projects'} offset={50} duration={200}>
                <Button type={'second'}>Discover projects</Button>
              </Link>
              <Link to={'faq'} offset={50} duration={200}>
                <Button type={'second'}>How to start?</Button>
              </Link>
            </BannerButtons>
          </BannerInfo>

          <BannerImg>
            <img src="/images/banner-img.svg" alt="banner-img" />
          </BannerImg>
        </BannerContainer>
      </div>
    </BannerWrap>
  )
}

export default MainBanner
