import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
`
export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  gap: 48px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  height: 168px;
  
  @media screen and (max-width: 1195px) {
    flex-direction: column;
    align-items: flex-start;
    height: max-content;
    gap: 24px;
    padding: 32px;
  }
`
export const BgEllipseLeft = styled.div`
  top: -105px;
  z-index: 1;
  position: absolute;
  width: 370px;
  height: 370px;
  border-radius: 370px;
  opacity: 1;
  background: linear-gradient(133deg, rgba(87, 144, 255, 0.50) 14.79%, rgba(165, 36, 226, 0.00) 103.42%);
  filter: blur(150px);
  
  @media screen and (max-width: 1195px) {
    display: none;
  }
`
export const BgEllipseRight = styled.div`
  top: -105px;
  right: 0;
  z-index: 1;
  position: absolute;
  width: 370px;
  height: 370px;
  border-radius: 370px;
  opacity: 1;
  background: linear-gradient(133deg, rgba(87, 144, 255, 0.50) 14.79%, rgba(165, 36, 226, 0.00) 103.42%);
  filter: blur(150px);

  @media screen and (max-width: 1195px) {
    top: 55px;
  }
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  max-width: 230px;
`
export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  
  @media screen and (max-width: 1195px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    
    &:last-child {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
`
export const ItemText = styled.span`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
`
export const ATHText = styled.div`
  color: #3C6;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.023%;
`
export const CoinGecko = styled.img`
  width: 40px;
  height: 40px;
`
