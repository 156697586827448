import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 416px;
  padding: 34px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 20px;
  background: #FFF;
`
export const Heading = styled.div`
  color: #181833;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  opacity: 0.6;
`
export const InputWrapper = styled.div`
  margin-top: 26px;
  display: flex;
  gap: 8px;
`
export const TabsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 20px;
`
export const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 12px 17px;
  height: 48px;
  width: 98px;
  border-radius: 24px;
  border: ${p => (p.isActive ? '1px solid #3C6' : 'none')};
  background: #FFF;
  cursor: pointer;
`
export const TabText = styled.span`
  color: #181833;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
export const TabImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
`
export const Input = styled.input`
  flex: 1;
  padding: 10px 24px;

  border-radius: 32px;
  border: 1px solid rgba(24, 24, 51, 0.20);

  color: #181833;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
  }

  -moz-appearance: textfield;
`
export const Button = styled.button`
  height: 52px;
  padding: 10px 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 44px;
  border: 1px solid #3C6;
  background: #FFF;

  color: #3C6;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 184%;
  cursor: pointer;
`
export const ButtonDeposit = styled.button<{ isDisabled: boolean }>`
  height: 52px;
  width: 100%;
  margin-top: 16px;
  padding: 10px 24px;
  min-width: 353px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 44px;
  border: 1px solid #3C6;
  background: #3C6;

  color: #FFF;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 184%;
  cursor: pointer;
  transition: 0.3s;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
            pointer-events: none;
            opacity: .7;
          `}
`
export const RowsWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`
export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
export const RowImg = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 32px;
`
export const RowText = styled.div`
  color: #181833;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`
export const RowValue = styled.div`
  color: #181833;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
export const ExtraInfo = styled.div`
  margin-top: 9px;

  color: #181833;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  opacity: 0.5;
`
