import React from 'react'

const Info = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 8.5V13H12V15H8V13H9V10.5H8V8.5H11ZM11.5 6C11.5 6.82843 10.8284 7.5 10 7.5C9.1716 7.5 8.5 6.82843 8.5 6C8.5 5.17157 9.1716 4.5 10 4.5C10.8284 4.5 11.5 5.17157 11.5 6Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Info
