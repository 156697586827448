import Accessibility from './Assets/Accessibility'
import Trends from './Assets/Trends'
import Simplicity from './Assets/Simplicity'
import Security from './Assets/Security'

export const launchpadCards = [
  {
    id: 1,
    icon: Security,
    title: 'Security',
    subtitle:
      'Maximum protection for your investments. Projects on the launchpad undergo thorough security checks to ensure your funds are in safe hands',
  },
  {
    id: 2,
    icon: Simplicity,
    title: 'Simplicity',
    subtitle:
      'Ease of use for investment opportunities on the launchpad. No headaches - everything is transparent and intuitively understandable',
  },
  {
    id: 3,
    icon: Trends,
    title: 'Trends',
    subtitle:
      'Stay on top of trends! The launchpad offers access to the mostpromising projects that align with current market trends',
  },
  {
    id: 4,
    icon: Accessibility,
    title: 'Accessibility',
    subtitle:
      'Get involved, even if it seems challenging! The launchpad makesinvestments in blockchain projects accessible',
  },
]
