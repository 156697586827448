import React from 'react'

const Funds = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M8.41085 18.5259L11.8125 15.1242L14.6409 17.9527L17.7978 14.7958L16.0049 13.0029H21.0049V18.0029L19.212 16.21L14.6409 20.7811L11.8125 17.9527L9.33834 20.4268C10.7716 22.5823 13.2223 24.0029 16.0049 24.0029C20.4232 24.0029 24.0049 20.4212 24.0049 16.0029C24.0049 11.5847 20.4232 8.00293 16.0049 8.00293C11.5866 8.00293 8.00488 11.5847 8.00488 16.0029C8.00488 16.8845 8.14747 17.7327 8.41085 18.5259ZM6.87288 20.0841L6.86275 20.074L6.86662 20.0701C6.31276 18.8275 6.00488 17.4512 6.00488 16.0029C6.00488 10.4801 10.482 6.00293 16.0049 6.00293C21.5277 6.00293 26.0049 10.4801 26.0049 16.0029C26.0049 21.5258 21.5277 26.0029 16.0049 26.0029C11.9357 26.0029 8.43426 23.5725 6.87288 20.0841Z"
        fill="white"
      />
    </svg>
  )
}

export default Funds
