import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ProfessionalLanding from './widgets/ProfessionalLanding'
import BeginnerLanding from './widgets/BeginnerLanding'
import Project from './widgets/Project'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<Navigate to="/professional" />} />
        <Route path={'/beginner'} element={<BeginnerLanding />} />
        <Route path={'/professional'} element={<ProfessionalLanding />} />
        <Route path={'/projects/:id'} element={<Project />} />
        <Route path={'*'} element={<Navigate to="/professional" />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

export default App
