import React from 'react'
import { ProjectType } from '../../../../types/project'
import { Wrapper, Heading, ExtraInfo } from './styled'
import Timer from '../Common/Timer'
import { getNumberFromBigInt } from '../../../../utils/formatBigInt'
import { useUserInfo } from '../../../../hooks/useUserInfo'

type WaitingForClaimProps = {
  project: ProjectType
}
const WaitingForClaim = ({ project }: WaitingForClaimProps) => {
  const { purchasedAmount } = useUserInfo(project.id)

  return (
    <Wrapper>
      <Heading>Waiting for claim</Heading>
      <Timer dateInSeconds={project.claim} />
      <ExtraInfo>
        You {project.isClaimable ? 'can claim' : 'will get'}:{' '}
        {getNumberFromBigInt(purchasedAmount).toFixed(1)} {project.tokenName}
      </ExtraInfo>
    </Wrapper>
  )
}

export default WaitingForClaim
