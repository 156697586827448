import React, { useState } from 'react'
import {
  ProjectsWrapper,
  Container,
  Title,
  TitleAndSwitcherWrapper,
  ProjectsScrollWrapper,
  BlueBackground,
  NoActiveProjectWrapper,
  NoActiveText,
} from './styled'
import Card from './Project'
import { projects } from '../../config/projects'
import Switcher from '../UIKit/Switcher'
import { useNavigate } from 'react-router-dom'

const switcherButtons = ['Live', 'Upcoming', 'Closed']

const Projects = () => {
  const navigate = useNavigate()
  const getDefaultSelectedType = () => {
    if (projects.some(project => project.type === 'live')) {
      return 0
    } else if (projects.some(project => project.type === 'upcoming')) {
      return 1
    }
    return 2
  }
  const [selectedType, setSelectedType] = useState(getDefaultSelectedType)

  const navigateToProject = (id: number) => {
    navigate(`/projects/${id}`, { replace: true })
  }

  const typeMapping: { [key: number]: string } = {
    0: 'live',
    1: 'upcoming',
    2: 'closed',
  }

  const filteredProjects = projects?.filter(
    project => project.type === typeMapping[selectedType],
  )

  return (
    <Container>
      <BlueBackground />
      <div className={'_container'}>
        <TitleAndSwitcherWrapper>
          <Title>Projects</Title>
          <Switcher
            firstSelectedIndex={selectedType}
            onChange={setSelectedType}
            buttons={switcherButtons}
            width={360}
          />
        </TitleAndSwitcherWrapper>
        <ProjectsScrollWrapper>
          <ProjectsWrapper>
            {filteredProjects?.length > 0 ? (
              filteredProjects.map((project, index) => (
                <Card
                  key={index}
                  project={project}
                  onClick={() => navigateToProject(project.id)}
                />
              ))
            ) : (
              <NoActiveProjectWrapper>
                <NoActiveText>
                  No active <span>{typeMapping[selectedType]}</span> pools.
                </NoActiveText>
              </NoActiveProjectWrapper>
            )}
          </ProjectsWrapper>
        </ProjectsScrollWrapper>
      </div>
    </Container>
  )
}

export default Projects
