const localeOptions: any = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const formatDate = (seconds: Date) => {
  return seconds.toLocaleString('en-GB', localeOptions)
}
