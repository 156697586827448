import React from 'react'
import {
  Wrapper,
  LogoAndLinksWrapper,
  LinksItemWrapper,
  LinksSection,
  LinksWrapperTitle,
  LinksWrapper,
  LinksWrapperText,
  Bottom,
  SocialNetworksWrapper,
  BottomText,
  Container,
} from './styled'

type FooterProps = {
  links?: { [key: string]: { title: string; href: string }[] }
}
const Footer = ({ links }: FooterProps) => {
  return (
    <Container>
      <div className={'_container'}>
        <Wrapper>
          {links && (
            <LogoAndLinksWrapper>
              <img src="/images/Logo.svg" alt="logo" />
              <LinksSection>
                {Object.keys(links).map(linkName => (
                  <LinksWrapper key={linkName}>
                    <LinksWrapperTitle>{linkName}</LinksWrapperTitle>
                    <LinksItemWrapper>
                      {links[linkName].map(link => (
                        <LinksWrapperText
                          key={link.href}
                          href={link.href}
                          target="_blank"
                        >
                          {link.title}
                        </LinksWrapperText>
                      ))}
                    </LinksItemWrapper>
                  </LinksWrapper>
                ))}
              </LinksSection>
              {/* <LinksSection> */}
              {/*   <LinksWrapper> */}
              {/*     <LinksWrapperTitle>Main</LinksWrapperTitle> */}
              {/*     <LinksItemWrapper> */}
              {/*       <LinksWrapperText */}
              {/*         href={'https://forms.gle/jZeXrnRW8VjKV7VDA'} */}
              {/*         target="_blank" */}
              {/*       > */}
              {/*         Apply for IDO */}
              {/*       </LinksWrapperText> */}
              {/*       <LinksWrapperText href={'https://t.me/MMPROWL_bot'} target="_blank"> */}
              {/*         Referral Program */}
              {/*       </LinksWrapperText> */}
              {/*       <LinksWrapperText */}
              {/*         href={'https://farming.marketmaking.pro/'} */}
              {/*         target="_blank" */}
              {/*       > */}
              {/*         Farming */}
              {/*       </LinksWrapperText> */}
              {/*     </LinksItemWrapper> */}
              {/*   </LinksWrapper> */}
              {/*   <LinksWrapper> */}
              {/*     <LinksWrapperTitle>Token</LinksWrapperTitle> */}
              {/*     <LinksItemWrapper> */}
              {/*       <LinksWrapperText */}
              {/*         href={'https://www.coingecko.com/en/coins/market-making-pro'} */}
              {/*         target="_blank" */}
              {/*       > */}
              {/*         CoinGecko */}
              {/*       </LinksWrapperText> */}
              {/*       <LinksWrapperText */}
              {/*         href={'https://coinmarketcap.com/currencies/mmpro-token/'} */}
              {/*         target="_blank" */}
              {/*       > */}
              {/*         CoinMarketCap */}
              {/*       </LinksWrapperText> */}
              {/*       <LinksWrapperText */}
              {/*         href={ */}
              {/*           'https://pancakeswap.finance/swap?outputCurrency=0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70' */}
              {/*         } */}
              {/*         target="_blank" */}
              {/*       > */}
              {/*         PancakeSwap */}
              {/*       </LinksWrapperText> */}
              {/*       <LinksWrapperText */}
              {/*         href={'https://skynet.certik.com/projects/mmpro-token'} */}
              {/*         target="_blank" */}
              {/*       > */}
              {/*         Certik */}
              {/*       </LinksWrapperText> */}
              {/*     </LinksItemWrapper> */}
              {/*   </LinksWrapper> */}
              {/* </LinksSection> */}
            </LogoAndLinksWrapper>
          )}
          <Bottom>
            <SocialNetworksWrapper>
              <a
                href={'https://marketmaking.medium.com/'}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/medium.svg" alt="medium" />
              </a>
              <a
                href={'https://x.com/MarketmakingX'}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/twitter.svg" alt="twitter" />
              </a>
              <a href={'https://t.me/marketmakingpro'} target="_blank" rel="noreferrer">
                <img src="/images/tg.svg" alt="tg" />
              </a>
              <a
                href={'https://www.linkedin.com/company/marketmakingpro/'}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/linkedin.svg" alt="linkedin" />
              </a>
            </SocialNetworksWrapper>
            <BottomText>
              All Rights Reserved 
              {/* | Terms and Conditions | Privacy Policy */}
            </BottomText>
          </Bottom>
        </Wrapper>
      </div>
    </Container>
  )
}

export default Footer
