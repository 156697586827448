import React from 'react'

const Playlist = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M6 22H16V24H6V22ZM6 15H26V17H6V15ZM6 8H26V10H6V8ZM22 22V19H24V22H27V24H24V27H22V24H19V22H22Z"
        fill="white"
      />
    </svg>
  )
}

export default Playlist
