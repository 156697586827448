export const MainContractAbi = [
  {
    'inputs': [
      {
        'internalType': 'contract IERC20',
        'name': '_MMpro',
        'type': 'address',
      },
      { 'internalType': 'address', 'name': '_feeAddress', 'type': 'address' },
      {
        'internalType': 'contract IERC20[]',
        'name': '_stablesInfo',
        'type': 'address[]',
      },
      { 'internalType': 'uint256', 'name': '_allocationDelay', 'type': 'uint256' },
    ],
    'stateMutability': 'nonpayable',
    'type': 'constructor',
  },
  {
    'anonymous': false,
    'inputs': [
      { 'indexed': true, 'internalType': 'address', 'name': 'user', 'type': 'address' },
      {
        'indexed': true,
        'internalType': 'uint256',
        'name': 'pid',
        'type': 'uint256',
      },
      {
        'indexed': false,
        'internalType': 'uint256',
        'name': 'amount',
        'type': 'uint256',
      },
    ],
    'name': 'BuyToken',
    'type': 'event',
  },
  {
    'anonymous': false,
    'inputs': [
      { 'indexed': false, 'internalType': 'uint256', 'name': 'feeBP', 'type': 'uint256' },
    ],
    'name': 'ChangeFeeBP',
    'type': 'event',
  },
  {
    'anonymous': false,
    'inputs': [
      { 'indexed': true, 'internalType': 'address', 'name': 'user', 'type': 'address' },
      {
        'indexed': true,
        'internalType': 'uint256',
        'name': 'pid',
        'type': 'uint256',
      },
      {
        'indexed': false,
        'internalType': 'uint256',
        'name': 'amount',
        'type': 'uint256',
      },
    ],
    'name': 'Deposit',
    'type': 'event',
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'previousOwner',
        'type': 'address',
      },
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'newOwner',
        'type': 'address',
      },
    ],
    'name': 'OwnershipTransferred',
    'type': 'event',
  },
  {
    'anonymous': false,
    'inputs': [
      { 'indexed': true, 'internalType': 'address', 'name': 'user', 'type': 'address' },
      {
        'indexed': true,
        'internalType': 'uint256',
        'name': 'pid',
        'type': 'uint256',
      },
      {
        'indexed': false,
        'internalType': 'uint256',
        'name': 'amount',
        'type': 'uint256',
      },
    ],
    'name': 'PickUpTokens',
    'type': 'event',
  },
  {
    'anonymous': false,
    'inputs': [
      { 'indexed': true, 'internalType': 'address', 'name': 'user', 'type': 'address' },
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'newAddress',
        'type': 'address',
      },
    ],
    'name': 'SetFeeAddress',
    'type': 'event',
  },
  {
    'anonymous': false,
    'inputs': [
      { 'indexed': true, 'internalType': 'uint256', 'name': 'pid', 'type': 'uint256' },
      {
        'indexed': false,
        'internalType': 'uint256',
        'name': 'amount',
        'type': 'uint256',
      },
    ],
    'name': 'TakeAwayUnsold',
    'type': 'event',
  },
  {
    'anonymous': false,
    'inputs': [
      { 'indexed': true, 'internalType': 'address', 'name': 'user', 'type': 'address' },
      {
        'indexed': true,
        'internalType': 'uint256',
        'name': 'pid',
        'type': 'uint256',
      },
      {
        'indexed': false,
        'internalType': 'uint256',
        'name': 'amount',
        'type': 'uint256',
      },
    ],
    'name': 'Withdraw',
    'type': 'event',
  },
  {
    'inputs': [],
    'name': 'FEE_MAX',
    'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [],
    'name': 'MMpro',
    'outputs': [{ 'internalType': 'contract IERC20', 'name': '', 'type': 'address' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [
      {
        'internalType': 'address',
        'name': '_poolOwner',
        'type': 'address',
      },
      { 'internalType': 'contract IERC20', 'name': '_lToken', 'type': 'address' },
      {
        'internalType': 'uint256',
        'name': '_lTokenPrice',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': '_tokensAllocationQty', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': '_depositLimit',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': '_startTimestamp', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': '_stakingDuration',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': '_purchaseDuration', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': '_lockupDuration',
        'type': 'uint256',
      },
      { 'internalType': 'bool', 'name': '_pickUpTokensAllowed', 'type': 'bool' },
    ],
    'name': 'add',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [],
    'name': 'allocationDelay',
    'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [
      { 'internalType': 'uint256', 'name': '_pid', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': '_amount',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': 'stableId', 'type': 'uint256' },
    ],
    'name': 'buyToken',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'uint256', 'name': '_feeBP', 'type': 'uint256' }],
    'name': 'changeFee',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [
      { 'internalType': 'uint256', 'name': '_pid', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': '_amount',
        'type': 'uint256',
      },
    ],
    'name': 'deposit',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [],
    'name': 'feeAddress',
    'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [],
    'name': 'owner',
    'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [
      { 'internalType': 'uint256', 'name': '_pid', 'type': 'uint256' },
      {
        'internalType': 'address',
        'name': '_user',
        'type': 'address',
      },
    ],
    'name': 'pendingAllocation',
    'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'uint256', 'name': '_pid', 'type': 'uint256' }],
    'name': 'pickUpTokens',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'name': 'pickUpTokensAllowed',
    'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'name': 'poolInfo',
    'outputs': [
      { 'internalType': 'address', 'name': 'owner', 'type': 'address' },
      {
        'internalType': 'contract IERC20',
        'name': 'lToken',
        'type': 'address',
      },
      { 'internalType': 'uint256', 'name': 'lTokenPrice', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': 'lTokenPerSec',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': 'depositLimit', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': 'startTimestamp',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': 'stakingEnd', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': 'purchaseEnd',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': 'lockupEnd', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': 'sharesTotal',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': 'lastRewardTimestamp', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': 'accLTokenPerShare',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': 'totalBought', 'type': 'uint256' },
    ],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [],
    'name': 'poolLength',
    'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [],
    'name': 'renounceOwnership',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'address', 'name': '_feeAddress', 'type': 'address' }],
    'name': 'setFeeAddress',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'name': 'stablesInfo',
    'outputs': [{ 'internalType': 'contract IERC20', 'name': '', 'type': 'address' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'uint256', 'name': '_pid', 'type': 'uint256' }],
    'name': 'takeAwayUnsold',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
    'name': 'totalAllocation',
    'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'address', 'name': 'newOwner', 'type': 'address' }],
    'name': 'transferOwnership',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [{ 'internalType': 'uint256', 'name': '_pid', 'type': 'uint256' }],
    'name': 'updatePool',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
  {
    'inputs': [
      { 'internalType': 'address', 'name': '', 'type': 'address' },
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256',
      },
    ],
    'name': 'userInfo',
    'outputs': [
      { 'internalType': 'uint256', 'name': 'totalDeposit', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': 'allocatedAmount',
        'type': 'uint256',
      },
      { 'internalType': 'uint256', 'name': 'purchasedAmount', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': 'rewardDebt',
        'type': 'uint256',
      },
    ],
    'stateMutability': 'view',
    'type': 'function',
  },
  {
    'inputs': [
      { 'internalType': 'uint256', 'name': '_pid', 'type': 'uint256' },
      {
        'internalType': 'uint256',
        'name': '_amount',
        'type': 'uint256',
      },
    ],
    'name': 'withdraw',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function',
  },
] as const
