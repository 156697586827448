import React from 'react'

const Influencers = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="white" />
      <path
        d="M20 22V30H12C12 25.5817 15.5817 22 20 22ZM26 29.5L23.0611 31.0451L23.6224 27.7725L21.2447 25.4549L24.5305 24.9775L26 22L27.4695 24.9775L30.7553 25.4549L28.3776 27.7725L28.9389 31.0451L26 29.5ZM20 21C16.685 21 14 18.315 14 15C14 11.685 16.685 9 20 9C23.315 9 26 11.685 26 15C26 18.315 23.315 21 20 21Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Influencers
