import React, { useState } from 'react'
import { Wrapper, Selector, Button } from './styled'

type SwitcherProps = {
  firstSelectedIndex: number
  onChange: (buttonIndex: number) => void
  buttons: string[]
  width: number
}

const Switcher = (props: SwitcherProps) => {
  const { firstSelectedIndex, onChange, buttons, width } = props

  const buttonWidthInPercent = 100 / buttons.length

  const [selectedButton, setSelectedButton] = useState<number>(firstSelectedIndex)
  const onButtonClick = (index: number) => {
    setSelectedButton(index)
    onChange(index)
  }

  return (
    <Wrapper width={width}>
      <Selector
        left={selectedButton * buttonWidthInPercent}
        width={buttonWidthInPercent}
      />
      {buttons.map((buttonTitle, index) => {
        return (
          <Button
            key={buttonTitle}
            type="button"
            value={index}
            onClick={() => onButtonClick(index)}
            width={buttonWidthInPercent}
            className={`${selectedButton === index && 'selected'}`}
          >
            {buttonTitle}
          </Button>
        )
      })}
    </Wrapper>
  )
}

export default Switcher
