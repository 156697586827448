import React from 'react'

const Allocation = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M11 25.5C8.51472 25.5 6.5 23.4853 6.5 21C6.5 18.5147 8.51472 16.5 11 16.5C13.4853 16.5 15.5 18.5147 15.5 21C15.5 23.4853 13.4853 25.5 11 25.5ZM21 15.5C18.5147 15.5 16.5 13.4853 16.5 11C16.5 8.51472 18.5147 6.5 21 6.5C23.4853 6.5 25.5 8.51472 25.5 11C25.5 13.4853 23.4853 15.5 21 15.5ZM11 23.5C12.3807 23.5 13.5 22.3807 13.5 21C13.5 19.6193 12.3807 18.5 11 18.5C9.61929 18.5 8.5 19.6193 8.5 21C8.5 22.3807 9.61929 23.5 11 23.5ZM21 13.5C22.3807 13.5 23.5 12.3807 23.5 11C23.5 9.61929 22.3807 8.5 21 8.5C19.6193 8.5 18.5 9.61929 18.5 11C18.5 12.3807 19.6193 13.5 21 13.5ZM7 12C7 9.23858 9.23858 7 12 7H15V9H12C10.3432 9 9 10.3432 9 12V15H7V12ZM25 17H23V20C23 21.6569 21.6569 23 20 23H17V25H20C22.7614 25 25 22.7614 25 20V17Z"
        fill="white"
      />
    </svg>
  )
}

export default Allocation
