import React from 'react'

const UtilitiesLabs = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8C12.2091 8 14 6.20914 14 4C14 1.79086 12.2091 0 10 0C7.79086 0 6 1.79086 6 4C6 6.20914 7.79086 8 10 8ZM3.5 11C4.88071 11 6 9.8807 6 8.5C6 7.11929 4.88071 6 3.5 6C2.11929 6 1 7.11929 1 8.5C1 9.8807 2.11929 11 3.5 11ZM19 8.5C19 9.8807 17.8807 11 16.5 11C15.1193 11 14 9.8807 14 8.5C14 7.11929 15.1193 6 16.5 6C17.8807 6 19 7.11929 19 8.5ZM10 9C12.7614 9 15 11.2386 15 14V20H5V14C5 11.2386 7.23858 9 10 9ZM3 13.9999C3 13.307 3.10067 12.6376 3.28818 12.0056L3.11864 12.0204C1.36503 12.2104 0 13.6958 0 15.4999V19.9999H3V13.9999ZM20 19.9999V15.4999C20 13.6378 18.5459 12.1153 16.7118 12.0056C16.8993 12.6376 17 13.307 17 13.9999V19.9999H20Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default UtilitiesLabs
