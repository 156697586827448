import React from 'react'

const Arrow = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0037 9.91421L7.39712 18.5208L5.98291 17.1066L14.5895 8.5H7.00373V6.5H18.0037V17.5H16.0037V9.91421Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Arrow
