import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  width: 416px;
  height: 208px;
  border-radius: 12px;
  background: #FFF;
  
  @media screen and (max-width: 767px) {
    padding: 20px;
    flex-direction: column;
    width: 100%;
  }
`
export const Icon = styled.div`
  display: flex;
  order: 0;

  @media screen and (max-width: 767px) {
    order: 1;
  }
`
export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  order: 1;

  @media screen and (max-width: 767px) {
    order: 0;
  }
`
export const StepText = styled.div`
  color: #3C6;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  max-width: 160px;

  @media screen and (max-width: 767px) {
    max-width: 260px;
  }
`
