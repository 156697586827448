import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  
  @media screen and (max-width: 767px) {
    overflow-y: hidden;
  }
`
export const BgEllipseLeft = styled.div`
  top: 105px;
  z-index: 1;
  position: absolute;
  width: 570px;
  height: 570px;
  border-radius: 370px;
  opacity: 1;
  background: linear-gradient(133deg, rgba(87, 144, 255, 0.50) 14.79%, rgba(165, 36, 226, 0.00) 103.42%);
  filter: blur(150px);

  @media screen and (max-width: 767px) {
    top: 200px;
    width: 270px;
    height: 270px;
  }
`
export const BgEllipseRight = styled.div`
  top: 105px;
  right: 0;
  z-index: 1;
  position: absolute;
  width: 570px;
  height: 570px;
  border-radius: 370px;
  opacity: 1;
  background: linear-gradient(133deg, rgba(87, 144, 255, 0.50) 14.79%, rgba(165, 36, 226, 0.00) 103.42%);
  filter: blur(150px);

  @media screen and (max-width: 767px) {
   top: 750px;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;

  @media screen and (max-width: 767px) {
    gap: 24px;
  }
`
export const Title = styled.div`
  position: relative;
  z-index: 2;
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;
  
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
`
export const CardsWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media screen and (max-width: 767px) {
    gap: 12px;
  }
`
