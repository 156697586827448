import { ProjectType } from '../types/project'

export const projects: ProjectType[] = [
  {
    id: 6,
    name: 'Kraken',
    type_sale: 'Limit sale',
    subscription_start: '23/01/2022, 22:00:00',
    description:
      'Kraken is one of the oldest cryptocurrency exchanges and the largest BTC / EUR exchange.',
    total_raise: 300000,
    ticker: 'KRAKEN',
    price_per_share: 64,
    shares_for_sale: 1875,
    mmpro_cap_per_user: null,
    image: '/images/projects/Kraken.svg',
    tokenName: 'KRAKEN',
    type: 'closed',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 100000,
    tba: false,
    tokenForSale: 1875,
    price: 64,
    subStartTime: 1642953600,
    subEndTime: 1644249600,
    saleOpen: 1644249660,
    saleClose: 1644422400,
    claim: 1644422460,
    isIPO: true,
    showStartTime: true,
    isClaimable: false,
  },
  {
    id: 9,
    name: 'Bitpay',
    type_sale: 'Limit sale',
    subscription_start: '11/02/2022, 22:00:00',
    description: `BitPay, the world's largest provider of Bitcoin and cryptocurrency payment services`,
    total_raise: 300000,
    ticker: 'BITPAY',
    price_per_share: 8.5,
    shares_for_sale: 20000,
    mmpro_cap_per_user: null,
    image: '/images/projects/Bitpay.svg',
    tokenName: 'BITPAY',
    type: 'closed',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 100000,
    tba: false,
    tokenForSale: 20000,
    price: 8.5,
    subStartTime: 1644595200,
    subEndTime: 1645459200,
    saleOpen: 1645459260,
    saleClose: 1645632000,
    claim: 1645632060,
    isIPO: true,
    showStartTime: true,
    isClaimable: false,
  },
  {
    id: 11,
    name: 'MetaMask',
    type_sale: 'Limit sale',
    subscription_start: '22/04/2022, 22:00:00',
    description:
      'A crypto wallet & gateway to blockchain apps. Trusted by over 21 million users worldwide.',
    total_raise: 300000,
    ticker: 'METAMASK',
    price_per_share: 150,
    shares_for_sale: 5000,
    mmpro_cap_per_user: null,
    image: '/images/projects/Metamask.svg',
    tokenName: 'METAMASK',
    type: 'closed',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    tba: false,
    tokenForSale: 5000,
    price: 150,
    subStartTime: 1650643200,
    subEndTime: 1651507200,
    saleOpen: 1651507260,
    saleClose: 1651766400,
    claim: 1651766460,
    isIPO: true,
    showStartTime: true,
    isClaimable: false,
  },
  {
    id: 13,
    name: 'ZimaBank',
    type_sale: 'Limit sale',
    subscription_start: '10/05/2022, 18:00:00',
    description:
      'Zima Bank offers a user-friendly way to earn, spend, and access banking services for cryptocurrency globally, catering to private clients, businesses, traders, investors, and crypto users.',
    total_raise: 300000,
    ticker: 'ZIMA',
    price_per_share: 0.2,
    shares_for_sale: 3000000,
    mmpro_cap_per_user: null,
    image: '/images/projects/Zima.svg',
    tokenName: 'ZIMABANK',
    type: 'closed',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    tba: false,
    tokenForSale: 3000000,
    price: 0.2,
    subStartTime: 1652184000,
    subEndTime: 1653048000,
    saleOpen: 1653048060,
    saleClose: 1653307200,
    claim: 1653307260,
    isIPO: false,
    showStartTime: true,
    isClaimable: false,
  },
  {
    id: 15,
    name: 'Ledger',
    type_sale: 'Limit sale',
    subscription_start: '21/04/2023, 18:00:00',
    description:
      'Ledger wallets are hardware wallets by a Paris-based company called Ledger. They securely store private keys for multiple cryptocurrencies offline.',
    total_raise: 300000,
    ticker: 'LEDGER',
    price_per_share: 12,
    shares_for_sale: 25000,
    mmpro_cap_per_user: null,
    image: '/images/projects/Ledger.svg',
    tokenName: 'LEDGER',
    type: 'closed',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 0,
    tba: false,
    tokenForSale: 25000,
    price: 12,
    subStartTime: 1682078400,
    subEndTime: 1682683200,
    saleOpen: 1682683260,
    saleClose: 1682856000,
    claim: 1682856060,
    isIPO: true,
    showStartTime: true,
    isClaimable: false,
  },
  {
    id: 20,
    name: 'ivendPay',
    type_sale: 'Limit sale',
    subscription_start: '08/12/2023, 18:00:00',
    description:
      'Second Private round. Crypto payment system for vending, retail & e-commerce. Easy. Safe. Fast.',
    total_raise: 300000,
    ticker: 'IVPAY',
    price_per_share: 0.025,
    shares_for_sale: 6000000,
    mmpro_cap_per_user: null,
    image: '/images/projects/Ivend.svg',
    tokenName: 'IVPAY',
    type: 'closed',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    tba: false,
    tokenForSale: 6000000,
    price: 0.025,
    subStartTime: 1702036800,
    subEndTime: 1702468800,
    saleOpen: 1702468860,
    saleClose: 1702641600,
    claim: 1702641660,
    isIPO: false,
    showStartTime: true,
    isClaimable: false,
  },
  {
    id: 21,
    name: 'Farcana',
    type_sale: 'Limit sale',
    subscription_start: '30/12/2023, 17:00:00',
    description:
      'Farcana is a third-person Sci-Fi shooter with eSports emphasis, set on colonized Mars, featuring 4x4 PvP combat in multiple modes.',
    total_raise: 300000,
    ticker: 'FAR',
    price_per_share: 0.012,
    shares_for_sale: 8000000,
    mmpro_cap_per_user: 10000,
    image: '/images/projects/farcana.png',
    tokenName: 'FAR',
    type: 'closed',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    tba: false,
    tokenForSale: 8000000,
    price: 0.012,
    subStartTime: 1703934000,
    subEndTime: 1704002400,
    saleOpen: 1704002460,
    saleClose: 1704020400,
    claim: 1704020460,
    isIPO: false,
    showStartTime: true,
    isClaimable: false,
  },
  {
    id: 30,
    name: 'ZimaBank',
    type_sale: 'Limit sale',
    subscription_start: '10/05/2022, 18:00:00',
    description:
      'Zima Bank offers a user-friendly way to earn, spend, and access banking services for cryptocurrency globally, catering to private clients, businesses, traders, investors, and crypto users.',
    total_raise: 300000,
    ticker: 'ZIMA',
    price_per_share: 0.25,
    shares_for_sale: 2400000,
    mmpro_cap_per_user: null,
    image: '/images/projects/Zima.svg',
    tokenName: 'ZIMABANK',
    type: 'upcoming',
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    tba: true,
    tokenForSale: 3000000,
    price: 0.2,
    subStartTime: 1652184000,
    subEndTime: 1653048000,
    saleOpen: 1653048060,
    saleClose: 1653307200,
    claim: 1653307260,
    isIPO: false,
    showStartTime: true,
    isClaimable: false,
  },
]
