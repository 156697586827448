import React from 'react'
import {
  Wrapper,
  Decoration,
  ContentWrapper,
  Title,
  Texts,
  Subtitle,
  CardsWrapper,
  CardsSideTitle,
} from './styled'
import DecorationIcon from './Assets/Decoration.svg'
import GenericCard from '../GenericCard'
import { benefitsCards } from './data'
const Benefits = () => {
  return (
    <Wrapper>
      <Decoration src={DecorationIcon} />
      <div className={'_container'}>
        <ContentWrapper>
          <Texts>
            <Title>What are the benefits of MMPRO Launchpad?</Title>
            <Subtitle>
              MMPRO Launchpad offers exclusive access to the most promising projects in
              their early stages of development
            </Subtitle>
          </Texts>
          <CardsWrapper>
            <CardsSideTitle>Launchpad Advantage</CardsSideTitle>
            {benefitsCards.map(card => (
              <GenericCard
                type={'light'}
                key={card.id}
                icon={card.icon()}
                title={card.title}
                subtitle={card.subtitle}
              />
            ))}
          </CardsWrapper>
        </ContentWrapper>
      </div>
    </Wrapper>
  )
}

export default Benefits
