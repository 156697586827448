import React from 'react'

const Ivend = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
        fill="white"
      />
      <path
        d="M6.00081 22.9942C6.00081 18.0253 6.00081 15.5409 6.8117 13.5843C7.89467 10.9719 9.96992 8.89797 12.584 7.8157C14.5472 7 17.0332 7 22.0053 7C26.9774 7 29.4634 7 31.4213 7.81037C34.0353 8.89264 36.1106 10.9666 37.1936 13.5789C38.0098 15.5409 38.0098 18.0253 38.0098 22.9942C38.0098 27.963 38.0098 30.4474 37.1989 32.4041C36.1159 35.0164 34.0407 37.0904 31.4266 38.1726C29.4634 38.983 26.9827 38.983 22.0106 38.983C17.0386 38.983 14.5525 38.983 12.5947 38.1726C9.98059 37.0904 7.90534 35.0164 6.82237 32.4041C6.00081 30.4421 6.00081 27.9577 6.00081 22.9942Z"
        fill="url(#paint0_linear_324_2027)"
      />
      <path
        d="M13.31 22.3494H12.3017C12.0669 22.3494 11.8802 22.536 11.8802 22.7705V23.2237C11.8802 23.4583 12.0669 23.6449 12.3017 23.6449H13.31C13.5447 23.6449 13.7314 23.4583 13.7314 23.2237V22.7705C13.7367 22.536 13.5447 22.3494 13.31 22.3494Z"
        fill="white"
      />
      <path
        d="M14.8406 22.7703V23.2235C14.8406 23.4581 15.0273 23.6447 15.2621 23.6447H21.2264H21.8292H22.1066C22.3414 23.6447 22.5281 23.4581 22.5281 23.2235V13.4297C22.5281 13.1951 22.3414 13.0085 22.1066 13.0085H21.6532C21.4185 13.0085 21.2317 13.1951 21.2317 13.4297V21.9226C21.2317 22.1572 21.045 22.3438 20.8103 22.3438H15.2621C15.0273 22.3491 14.8406 22.5357 14.8406 22.7703Z"
        fill="white"
      />
      <path
        d="M25.9266 24.9934H21.6534C21.4187 24.9934 21.2319 25.18 21.2319 25.4146V25.8678C21.2319 26.1023 21.4187 26.2889 21.6534 26.2889H25.9266C26.1613 26.2889 26.3481 26.1023 26.3481 25.8678V25.4146C26.3481 25.1853 26.1613 24.9934 25.9266 24.9934Z"
        fill="white"
      />
      <path
        d="M30.1523 31.6845H26.77C26.5352 31.6845 26.3485 31.8711 26.3485 32.1057V32.5589C26.3485 32.7935 26.5352 32.9801 26.77 32.9801H30.5737H31.454C31.6887 32.9801 31.8754 32.7935 31.8754 32.5589V31.6792V28.5603C31.8754 28.3258 31.6887 28.1392 31.454 28.1392H31.0005C30.7658 28.1392 30.579 28.3258 30.579 28.5603V31.258C30.5737 31.4979 30.387 31.6845 30.1523 31.6845Z"
        fill="white"
      />
      <path
        d="M28.0714 29.4452H28.9143C29.1491 29.4452 29.3358 29.2586 29.3358 29.024V28.5708C29.3358 28.3363 29.1491 28.1497 28.9143 28.1497H27.6447H26.7644C26.5297 28.1497 26.343 28.3363 26.343 28.5708V29.8717C26.343 30.1063 26.5297 30.2929 26.7644 30.2929H27.2179C27.4526 30.2929 27.6393 30.1063 27.6393 29.8717C27.65 29.6318 27.8367 29.4452 28.0714 29.4452Z"
        fill="white"
      />
      <path
        d="M28.4606 25.3349H28.9141C29.1488 25.3349 29.3355 25.1483 29.3355 24.9138V22.7705C29.3355 22.536 29.1488 22.3494 28.9141 22.3494H28.4286H28.0392H24.2995C24.0647 22.3494 23.878 22.536 23.878 22.7705V23.2237C23.878 23.4583 24.0647 23.6449 24.2995 23.6449H27.6177C27.8525 23.6449 28.0392 23.8315 28.0392 24.0661V24.9084C28.0392 25.1483 28.2259 25.3349 28.4606 25.3349Z"
        fill="white"
      />
      <path
        d="M30.9307 26.2945H31.4482C31.6829 26.2945 31.8696 26.1079 31.8696 25.8734V25.4202C31.8696 25.1856 31.6829 24.999 31.4482 24.999H30.9307C30.696 24.999 30.5092 25.1856 30.5092 25.4202V25.8734C30.5092 26.1026 30.696 26.2945 30.9307 26.2945Z"
        fill="white"
      />
      <path
        d="M22.7897 29.2481H21.6534C21.4187 29.2481 21.2319 29.4347 21.2319 29.6693V29.8292V30.5436V32.5589C21.2319 32.7935 21.4187 32.9801 21.6534 32.9801H22.1069C22.3416 32.9801 22.5283 32.7935 22.5283 32.5589V30.9648C22.5283 30.7302 22.715 30.5436 22.9498 30.5436H23.2112H24.0914C24.3262 30.5436 24.5129 30.357 24.5129 30.1224V29.2428V28.5603C24.5129 28.3258 24.3262 28.1392 24.0914 28.1392H23.638C23.4032 28.1392 23.2165 28.3258 23.2165 28.5603V28.8216C23.2112 29.0615 23.0191 29.2481 22.7897 29.2481Z"
        fill="white"
      />
      <path
        d="M17.1882 25.8945V31.8071H13.0537V25.8945H17.1882ZM17.9457 24.7163H12.2962C12.0614 24.7163 11.8747 24.9029 11.8747 25.1375V32.5641C11.8747 32.7987 12.0614 32.9853 12.2962 32.9853H17.9457C18.1805 32.9853 18.3672 32.7987 18.3672 32.5641V25.1375C18.3725 24.9029 18.1805 24.7163 17.9457 24.7163Z"
        fill="white"
      />
      <path
        d="M19.3218 26.7369C19.4179 26.7369 19.4979 26.8169 19.4979 26.9128V29.8131C19.4979 29.9091 19.4179 29.989 19.3218 29.989H18.3296V26.7369H19.3218ZM19.3218 25.8945H17.4867V30.8314H19.3218C19.8873 30.8314 20.3408 30.3729 20.3408 29.8131V26.9128C20.3408 26.353 19.8873 25.8945 19.3218 25.8945Z"
        fill="white"
      />
      <path d="M20.3411 27.4141H17.9297V28.427H20.3411V27.4141Z" fill="white" />
      <path
        d="M14.2809 27.7821H15.9827C16.3614 27.7821 16.6655 27.4782 16.6655 27.0997C16.6655 26.7211 16.3614 26.4172 15.9827 26.4172H14.2809C13.9021 26.4172 13.598 26.7211 13.598 27.0997C13.598 27.4782 13.9074 27.7821 14.2809 27.7821Z"
        fill="white"
      />
      <path
        d="M16.2436 29.0614C16.4784 29.0614 16.6651 28.8748 16.6651 28.6402C16.6651 28.4056 16.4784 28.219 16.2436 28.219C16.0089 28.219 15.8222 28.4056 15.8222 28.6402C15.8222 28.8748 16.0142 29.0614 16.2436 29.0614Z"
        fill="white"
      />
      <path
        d="M14.0195 29.0614C14.2542 29.0614 14.4409 28.8748 14.4409 28.6402C14.4409 28.4056 14.2542 28.219 14.0195 28.219C13.7847 28.219 13.598 28.4056 13.598 28.6402C13.598 28.8748 13.7901 29.0614 14.0195 29.0614Z"
        fill="white"
      />
      <path
        d="M15.1343 29.0614C15.369 29.0614 15.5558 28.8748 15.5558 28.6402C15.5558 28.4056 15.369 28.219 15.1343 28.219C14.8996 28.219 14.7129 28.4056 14.7129 28.6402C14.7129 28.8748 14.8996 29.0614 15.1343 29.0614Z"
        fill="white"
      />
      <path
        d="M16.2436 30.1756C16.4784 30.1756 16.6651 29.989 16.6651 29.7544C16.6651 29.5199 16.4784 29.3333 16.2436 29.3333C16.0089 29.3333 15.8222 29.5199 15.8222 29.7544C15.8222 29.9837 16.0142 30.1756 16.2436 30.1756Z"
        fill="white"
      />
      <path
        d="M14.0195 30.1756C14.2542 30.1756 14.4409 29.989 14.4409 29.7544C14.4409 29.5199 14.2542 29.3333 14.0195 29.3333C13.7847 29.3333 13.598 29.5199 13.598 29.7544C13.598 29.9837 13.7901 30.1756 14.0195 30.1756Z"
        fill="white"
      />
      <path
        d="M15.1343 30.1756C15.369 30.1756 15.5558 29.989 15.5558 29.7544C15.5558 29.5199 15.369 29.3333 15.1343 29.3333C14.8996 29.3333 14.7129 29.5199 14.7129 29.7544C14.7129 29.9837 14.8996 30.1756 15.1343 30.1756Z"
        fill="white"
      />
      <path
        d="M16.2436 31.2847C16.4784 31.2847 16.6651 31.0981 16.6651 30.8636C16.6651 30.629 16.4784 30.4424 16.2436 30.4424C16.0089 30.4424 15.8222 30.629 15.8222 30.8636C15.8222 31.0981 16.0142 31.2847 16.2436 31.2847Z"
        fill="white"
      />
      <path
        d="M14.0195 31.2847C14.2542 31.2847 14.4409 31.0981 14.4409 30.8636C14.4409 30.629 14.2542 30.4424 14.0195 30.4424C13.7847 30.4424 13.598 30.629 13.598 30.8636C13.598 31.0981 13.7901 31.2847 14.0195 31.2847Z"
        fill="white"
      />
      <path
        d="M15.1343 31.2847C15.369 31.2847 15.5558 31.0981 15.5558 30.8636C15.5558 30.629 15.369 30.4424 15.1343 30.4424C14.8996 30.4424 14.7129 30.629 14.7129 30.8636C14.7129 31.0981 14.8996 31.2847 15.1343 31.2847Z"
        fill="white"
      />
      <path
        d="M18.6928 14.1763V19.8116H13.0592V14.1763H18.6928ZM19.4503 12.998H12.3017C12.0669 12.998 11.8802 13.1846 11.8802 13.4192V20.5686C11.8802 20.8032 12.0669 20.9898 12.3017 20.9898H19.4503C19.6851 20.9898 19.8718 20.8032 19.8718 20.5686V13.4192C19.8718 13.19 19.6851 12.998 19.4503 12.998Z"
        fill="white"
      />
      <path
        d="M14.1583 15.4666H14.025C13.7902 15.4666 13.6035 15.28 13.6035 15.0454C13.6035 14.8109 13.7902 14.6243 14.025 14.6243H14.1583C14.3931 14.6243 14.5798 14.8109 14.5798 15.0454C14.5798 15.2747 14.3877 15.4666 14.1583 15.4666Z"
        fill="white"
      />
      <path
        d="M14.1583 16.65H14.025C13.7902 16.65 13.6035 16.4634 13.6035 16.2288C13.6035 15.9942 13.7902 15.8076 14.025 15.8076H14.1583C14.3931 15.8076 14.5798 15.9942 14.5798 16.2288C14.5798 16.4634 14.3877 16.65 14.1583 16.65Z"
        fill="white"
      />
      <path
        d="M14.1583 17.8389H14.025C13.7902 17.8389 13.6035 17.6523 13.6035 17.4178C13.6035 17.1832 13.7902 16.9966 14.025 16.9966H14.1583C14.3931 16.9966 14.5798 17.1832 14.5798 17.4178C14.5798 17.6523 14.3877 17.8389 14.1583 17.8389Z"
        fill="white"
      />
      <path
        d="M16.6867 15.4664H16.5533C16.3186 15.4664 16.1319 15.2798 16.1319 15.0452C16.1319 14.8106 16.3186 14.624 16.5533 14.624H16.6867C16.9215 14.624 17.1082 14.8106 17.1082 15.0452C17.1082 15.2745 16.9161 15.4664 16.6867 15.4664Z"
        fill="white"
      />
      <path
        d="M16.6867 16.65H16.5533C16.3186 16.65 16.1319 16.4634 16.1319 16.2288C16.1319 15.9942 16.3186 15.8076 16.5533 15.8076H16.6867C16.9215 15.8076 17.1082 15.9942 17.1082 16.2288C17.1082 16.4634 16.9161 16.65 16.6867 16.65Z"
        fill="white"
      />
      <path
        d="M16.6867 17.8389H16.5533C16.3186 17.8389 16.1319 17.6523 16.1319 17.4178C16.1319 17.1832 16.3186 16.9966 16.5533 16.9966H16.6867C16.9215 16.9966 17.1082 17.1832 17.1082 17.4178C17.1082 17.6523 16.9161 17.8389 16.6867 17.8389Z"
        fill="white"
      />
      <path
        d="M15.4228 15.4666H15.2841C15.0494 15.4666 14.8627 15.28 14.8627 15.0454C14.8627 14.8109 15.0494 14.6243 15.2841 14.6243H15.4175C15.6522 14.6243 15.8389 14.8109 15.8389 15.0454C15.8443 15.2747 15.6522 15.4666 15.4228 15.4666Z"
        fill="white"
      />
      <path
        d="M15.4228 16.65H15.2841C15.0494 16.65 14.8627 16.4634 14.8627 16.2288C14.8627 15.9942 15.0494 15.8076 15.2841 15.8076H15.4175C15.6522 15.8076 15.8389 15.9942 15.8389 16.2288C15.8443 16.4634 15.6522 16.65 15.4228 16.65Z"
        fill="white"
      />
      <path
        d="M15.4228 17.8389H15.2841C15.0494 17.8389 14.8627 17.6523 14.8627 17.4178C14.8627 17.1832 15.0494 16.9966 15.2841 16.9966H15.4175C15.6522 16.9966 15.8389 17.1832 15.8389 17.4178C15.8443 17.6523 15.6522 17.8389 15.4228 17.8389Z"
        fill="white"
      />
      <path
        d="M17.8707 19.3209C17.716 19.3209 17.5933 19.1982 17.5933 19.0436V18.6224C17.5933 18.4678 17.716 18.3452 17.8707 18.3452C18.0254 18.3452 18.1481 18.4678 18.1481 18.6224V19.0436C18.1534 19.1982 18.0254 19.3209 17.8707 19.3209Z"
        fill="white"
      />
      <path
        d="M13.598 18.8357C13.598 18.5638 13.8167 18.3452 14.0888 18.3452H16.6175C16.8896 18.3452 17.1083 18.5638 17.1083 18.8357C17.1083 19.1076 16.8896 19.3262 16.6175 19.3262H14.0888C13.8167 19.3209 13.598 19.1023 13.598 18.8357Z"
        fill="white"
      />
      <path
        d="M27.8475 18.8357C27.8475 18.5638 28.0663 18.3452 28.3384 18.3452H29.6347C29.9068 18.3452 30.1255 18.5638 30.1255 18.8357C30.1255 19.1076 29.9068 19.3262 29.6347 19.3262H28.3384C28.0663 19.3209 27.8475 19.1023 27.8475 18.8357Z"
        fill="white"
      />
      <path
        d="M30.6961 14.1816V19.8116H25.0625V14.1816H30.6961ZM31.4537 12.998H24.305C24.0702 12.998 23.8835 13.1846 23.8835 13.4192V20.5633C23.8835 20.7979 24.0702 20.9845 24.305 20.9845H31.4537C31.6884 20.9845 31.8751 20.7979 31.8751 20.5633V13.4192C31.8751 13.19 31.6884 12.998 31.4537 12.998Z"
        fill="white"
      />
      <path
        d="M26.1719 19.0493C26.0652 19.0493 25.9585 19.0066 25.8731 18.9267C25.7078 18.7614 25.7078 18.4948 25.8731 18.3295L26.476 17.7271L25.8411 17.0927C25.6758 16.9274 25.6758 16.6608 25.8411 16.4955C26.0065 16.3303 26.2733 16.3303 26.4386 16.4955L27.3722 17.4285C27.5376 17.5938 27.5376 17.8604 27.3722 18.0256L26.4706 18.9267C26.3906 19.0066 26.2839 19.0493 26.1719 19.0493Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_324_2027"
          x1="10.404"
          y1="9.24116"
          x2="33.3264"
          y2="36.4458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2522BA" />
          <stop offset="0.6738" stopColor="#3326E5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Ivend
