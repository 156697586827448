import React from 'react'
import {
  Wrapper,
  BgEllipseLeft,
  BgEllipseRight,
  Container,
  Title,
  ItemWrapper,
  ATHText,
  ItemText,
  CoinGecko,
} from './styled'
import Button from '../../../../components/UIKit/Button/Button'
import CoinGeckoImg from './Assets/CoinGecko.png'
import ArrowIcon from './Assets/Arrow'
const TokenDetails = () => {
  return (
    <Container>
      <BgEllipseLeft />
      <BgEllipseRight />
      <div className={'_container'}>
        <Wrapper>
          <Title>MMPRO token details</Title>
          <ItemWrapper>
            <ItemText>Smart contract</ItemText>
            <Button
              type={'base'}
              link={
                'https://www.bscscan.com/token/0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70'
              }
            >
              Contract Info
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <CoinGecko src={CoinGeckoImg} alt="" />
            <ItemText>CoinGecko</ItemText>
            <Button
              type={'base'}
              link={'https://www.coingecko.com/en/coins/market-making-pro'}
            >
              Learn more
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <ATHText>ATH</ATHText>
            <ArrowIcon />
            <ATHText>$0.82</ATHText>
          </ItemWrapper>
        </Wrapper>
      </div>
    </Container>
  )
}

export default TokenDetails
