import React from 'react'
import { ProjectStatus, ProjectType } from '../../../../../types/project'
import { getProjectStatus } from '../../../Utils/getProjectStatus'
import ToBeAnnounced from '../../ToBeAnnounced'
import LaunchpadFinished from '../../LaunchpadFinished'
import SubscriptionStartsIn from '../../SubscriptionStartsIn'
import SubscriptionEndedIn from '../../SubscriptionEndedIn'
import SalesStartIn from '../../SalesStartsIn'
import SalesEndIn from '../../SalesEndIn'
import WaitingForClaim from '../../WaitingForClaim'

type ProjectStatusRenderProps = {
  project: ProjectType
}
const ProjectStatusRender = ({ project }: ProjectStatusRenderProps) => {
  const activeStatus = getProjectStatus(project)

  const renderStatus = () => {
    switch (activeStatus) {
      case ProjectStatus.TBA:
        return <ToBeAnnounced />
      case ProjectStatus.SubscriptionStartIn:
        return <SubscriptionStartsIn project={project} />
      case ProjectStatus.SubscriptionEndedIn:
        return <SubscriptionEndedIn project={project} />
      case ProjectStatus.SaleStartIn:
        return <SalesStartIn project={project} />
      case ProjectStatus.SaleEndedIn:
        return <SalesEndIn project={project} />
      case ProjectStatus.WaitingForClaim:
        return <WaitingForClaim project={project} />
      case ProjectStatus.LaunchpadFinished:
        return <LaunchpadFinished project={project} />
    }
  }

  return <>{renderStatus()}</>
}

export default ProjectStatusRender
