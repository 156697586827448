import React from 'react'

const Crosshair = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M17 23.9381C20.6187 23.4869 23.4869 20.6187 23.9381 17H21V15H23.9381C23.4869 11.3813 20.6187 8.51314 17 8.06189V11H15V8.06189C11.3813 8.51314 8.51314 11.3813 8.06189 15H11V17H8.06189C8.51314 20.6187 11.3813 23.4869 15 23.9381V21H17V23.9381ZM16 26C10.4771 26 6 21.5228 6 16C6 10.4771 10.4771 6 16 6C21.5228 6 26 10.4771 26 16C26 21.5228 21.5228 26 16 26ZM16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16C18 17.1046 17.1046 18 16 18Z"
        fill="white"
      />
    </svg>
  )
}

export default Crosshair
