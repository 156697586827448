import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 640px;
  background: #33CC66;
  margin-top: 147px;

  @media screen and (max-width: 1195px) {
    height: max-content;
    padding-bottom: 22px;
  }
`
export const Decoration = styled.img`
  position: absolute;
  top: -147px;
  right: 207px;

  @media screen and (max-width: 1195px) {
    right: 50%;
    top: -107px;
    transform: translateX(50%);
    width: 223px;
    height: 223px;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding-top: 110px;
  width: 100%;
  
  @media screen and (max-width: 1195px) {
    gap: 24px;
  }
`
export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`
export const Title = styled.div`
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; 
  text-transform: uppercase;
  max-width: 530px;

  @media screen and (max-width: 1195px) {
    font-size: 24px;
  }
`
export const Subtitle = styled.div`
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  max-width: 640px;
  
  @media screen and (max-width: 1195px) {
    font-size: 16px;
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 32px;
  
  @media screen and (max-width: 1195px) {
    flex-direction: column;
    gap: 22px;
  }
`
export const CardsSideTitle = styled.div`
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  width: 230px;

  @media screen and (max-width: 1195px) {
    font-size: 20px;
  }
`
