export type ProjectType = {
  id: number
  name: string
  type_sale: string
  subscription_start: string
  description: string
  total_raise: number
  ticker: string
  price_per_share: number
  shares_for_sale: number
  mmpro_cap_per_user: null | number
  image: string
  tokenName: string
  type: 'upcoming' | 'live' | 'closed'
  limitSale: boolean
  stakingCap: number
  mmproCap: number
  tba: boolean
  tokenForSale: number
  price: number
  subStartTime: number
  subEndTime: number
  saleOpen: number
  saleClose: number
  claim: number
  isIPO: boolean
  showStartTime: boolean
  isClaimable: boolean
}
export enum ProjectStatus {
  TBA = 'To be announced',
  SubscriptionStartIn = 'Subscription Start In',
  SubscriptionEndedIn = 'Subscription Ended In',
  SaleStartIn = 'Sale Start In',
  SaleEndedIn = 'Sale Ended In',
  WaitingForClaim = 'Waiting For Claim',
  LaunchpadFinished = 'Launchpad Finished',
}
