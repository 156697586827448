import React from 'react'
import { Wrapper, Title, Texts, StepText, Icon } from './styled'
import { HowItWorkCard } from '../data'

const StageCard = ({ text, icon, index }: HowItWorkCard & { index: number }) => {
  return (
    <Wrapper>
      <Icon>{icon()}</Icon>
      <Texts>
        <StepText>Step {index + 1}</StepText>
        <Title>{text}</Title>
      </Texts>
    </Wrapper>
  )
}

export default StageCard
