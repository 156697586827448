import React from 'react'

const Quotes = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.75">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5556 33.4444V38.5H31.5V70H0.000775983L0 33.4444C0 21.4003 3.48649 11.429 10.3617 5.83328C15.1177 1.96256 20.8827 0 28 0V16.5789C24.1364 16.5789 15.5556 18.1255 15.5556 33.4444ZM54.0556 33.4444V38.5H70V70H38.5V33.4444C38.5 21.4003 41.5976 11.429 48.4728 5.83328C53.2288 1.96256 59.3827 0 66.5 0V16.5789C62.6364 16.5789 54.0556 18.1255 54.0556 33.4444Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default Quotes
