import styled from 'styled-components'

export const DateWrapper = styled.div`
    display: flex;
    gap: 16px;

    @media screen and (max-width: 768px) {
        gap: 8px;
    }
`
export const DateText = styled.div`
    display: flex;  
    gap: 6px;
    align-items: center;

    color: #181833;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 

    span{
        color: #181833;
        font-family: Gilroy;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; 

        @media screen and (max-width: 768px) {
            font-size: 28px;
        }
    }
`
