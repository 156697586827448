import React from 'react'

const Question = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="white" />
      <path
        d="M20.6667 20.7473H16.3333C16.3333 20.1612 16.4778 19.6026 16.7667 19.0714C17.0736 18.522 17.4347 18.0458 17.85 17.6429C18.2833 17.2216 18.7076 16.8187 19.1229 16.4341C19.5563 16.0494 19.9174 15.6374 20.2062 15.1978C20.5132 14.7582 20.6667 14.3187 20.6667 13.8791C20.6667 12.8168 19.9986 12.2857 18.6625 12.2857C17.3625 12.2857 16.3875 13.0549 15.7375 14.5934L12 12.4231C12.5958 11.0495 13.4986 9.96886 14.7083 9.18132C15.9361 8.39377 17.3083 8 18.825 8C20.4861 8 21.9306 8.4945 23.1583 9.48352C24.3861 10.4725 25 11.8095 25 13.4945C25 14.2454 24.8465 14.9505 24.5396 15.6099C24.2507 16.2692 23.8896 16.8278 23.4563 17.2857C23.041 17.7436 22.6167 18.1648 22.1833 18.5494C21.7681 18.9341 21.4069 19.3187 21.1 19.7033C20.8111 20.0696 20.6667 20.4176 20.6667 20.7473ZM21.0729 25.3901C21.0729 26.1044 20.8201 26.7179 20.3146 27.2308C19.809 27.7436 19.2042 28 18.5 28C17.7958 28 17.191 27.7436 16.6854 27.2308C16.1799 26.7179 15.9271 26.1044 15.9271 25.3901C15.9271 24.6758 16.1799 24.0623 16.6854 23.5495C17.191 23.0366 17.7958 22.7802 18.5 22.7802C19.2042 22.7802 19.809 23.0366 20.3146 23.5495C20.8201 24.0623 21.0729 24.6758 21.0729 25.3901Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default Question
