import React from 'react'
import { ProjectType } from '../../../types/project'
import {
  Wrapper,
  Title,
  TitleSection,
  Logo,
  Subscription,
  Description,
  TitleAndSubscriptionWrapper,
  TitleAngLogoWrapper,
  CardsWrapper,
  CardsSection,
  Card,
  CardTitle,
  CardValue,
} from './styled'
import { formatDate } from '../../../utils/formatDate'

type ProjectProps = {
  project: ProjectType
  onClick?: () => void
}

const Project = ({ project, onClick }: ProjectProps) => {
  const subStartTime = new Date(project.subStartTime * 1000)
  return (
    <Wrapper onClick={onClick}>
      <TitleSection>
        <TitleAngLogoWrapper>
          <TitleAndSubscriptionWrapper>
            <Title>{project.name}</Title>
            <Subscription>
              {project.tba
                ? 'To be announced'
                : `Subscription starts: ${formatDate(subStartTime)}`}
            </Subscription>
          </TitleAndSubscriptionWrapper>
          <Logo src={project.image} />
        </TitleAngLogoWrapper>
        <Description>{project.description}</Description>
      </TitleSection>
      <CardsSection>
        <CardsWrapper>
          <Card>
            <CardTitle>Total raise</CardTitle>
            <CardValue>{project.price_per_share * project.shares_for_sale}</CardValue>
          </Card>
          <Card>
            <CardTitle>Ticker</CardTitle>
            <CardValue>{project.ticker}</CardValue>
          </Card>
          <Card>
            <CardTitle>1 {project.ticker}</CardTitle>
            <CardValue>{project.price_per_share} USDT</CardValue>
          </Card>
          <Card>
            <CardTitle>Tokens For Sale</CardTitle>
            <CardValue>{project.shares_for_sale}</CardValue>
          </Card>
          <Card>
            <CardTitle>MMPRO Cap Per User</CardTitle>
            <CardValue>
              {project.mmpro_cap_per_user ? project.mmpro_cap_per_user : '-'}
            </CardValue>
          </Card>
        </CardsWrapper>
      </CardsSection>
    </Wrapper>
  )
}

export default Project
