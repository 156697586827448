import styled from 'styled-components'
export const Container = styled.div`
  width: 100%;
  background: #fff;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 65px;
  height: 100%;
  padding-top: 55px;
  padding-bottom: 55px;
  width: 100%;

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    padding: 0 30px 40px 30px;
  }
`
export const LogoAndLinksWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 260px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 40px;
  }
`

export const LinksSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 80px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 40px;
  }
`
export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
`
export const LinksWrapperTitle = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`
export const LinksWrapperText = styled.a`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.75;
  cursor: pointer;
`
export const LinksItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
`
export const SocialNetworksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  order: 0;

  @media screen and (max-width: 767px) {
    order: 1;
  }
`
export const BottomText = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.65;
  order: 0;

  @media screen and (max-width: 767px) {
    order: 0;
  }
`
