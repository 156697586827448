import React from 'react'

const Trends = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#33CC66" />
      <path
        d="M21.3135 20.4513L14.2424 27.5224L12.4747 25.7546L19.5457 18.6835L13.3585 12.4963H27.5007V26.6385L21.3135 20.4513Z"
        fill="white"
      />
    </svg>
  )
}

export default Trends
