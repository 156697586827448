import React, { ReactNode } from 'react'
import { Wrapper, Title, Subtitle } from './styled'

type GenericCardProps = {
  icon: ReactNode
  title: string
  subtitle: string
  type?: 'light' | 'dark'
}
const GenericCard = ({ icon, subtitle, title, type = 'dark' }: GenericCardProps) => {
  return (
    <Wrapper>
      {icon}
      <Title type={type}>{title}</Title>
      <Subtitle type={type}>{subtitle}</Subtitle>
    </Wrapper>
  )
}

export default GenericCard
