import React, { useState } from 'react'
import {
  Wrapper,
  TitleWrapper,
  CollapseWrapper,
  IconAndTitleWrapper,
  IconWrapper,
  Title,
  CollapseText,
  CheckMarkIcon,
} from './styled'
import Checkmark from '../../../assets/Checkmark'
import { collapses } from './data'
import * as HtmlToReactParser from 'html-to-react'

const Collapse = () => {
  const Parser = (HtmlToReactParser as any).Parser
  const htmlToReactParser = new Parser()
  const [activeCollapseId, setActiveCollapseId] = useState<number | null>(null)

  const toggleCollapse = (id: number | null) => {
    setActiveCollapseId(prevId => (prevId === id ? null : id))
  }

  return (
    <Wrapper>
      {collapses.map(collapse => (
        <CollapseWrapper key={collapse.id}>
          <TitleWrapper onClick={() => toggleCollapse(collapse.id)}>
            <IconAndTitleWrapper>
              <IconWrapper>{collapse.icon()}</IconWrapper>
              <Title>{htmlToReactParser.parse(collapse.title)}</Title>
            </IconAndTitleWrapper>
            <CheckMarkIcon>
              <Checkmark rotate={activeCollapseId === collapse.id ? 180 : 0} />
            </CheckMarkIcon>
          </TitleWrapper>
          <CollapseText isActive={activeCollapseId === collapse.id}>
            {htmlToReactParser.parse(collapse.text)}
          </CollapseText>
        </CollapseWrapper>
      ))}
    </Wrapper>
  )
}

export default Collapse
