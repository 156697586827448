import React from 'react'

const Gate = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0575 32.5284C17.8783 32.5284 13.6765 28.3133 13.6765 23.1176C13.6765 17.9219 17.8783 13.7068 23.0575 13.7068V6C13.6348 6 6 13.665 6 23.1176C6 32.5702 13.6348 40.2292 23.0575 40.2292C32.4743 40.2292 40.115 32.5643 40.115 23.1176H32.4386C32.4386 28.3192 28.2368 32.5284 23.0575 32.5284Z"
        fill="#2354E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0575 23.1178H32.4385V13.707H23.0575V23.1178Z"
        fill="#17E6A1"
      />
    </svg>
  )
}

export default Gate
