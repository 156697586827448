import React from 'react'
import styled from 'styled-components'
import Header from '../../components/Header'
import Main from './Components/Main'
import XFactor from './Components/XFactor'
import HowWorkLaunchpad from './Components/HowWorkLaunchpad'
import Benefits from './Components/Benefits'
import TokenDetails from './Components/TokenDetails'
import Utilities from '../../components/Utilities'
import About from '../../components/About'
import Projects from '../../components/Projects'
import Reviews from './Components/Reviews'
import Footer from '../../components/Footer'
import HowEarn from './Components/HowEarn'
import { Element } from 'react-scroll'
import HowWork from './Components/HowWork'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;

  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`

const links = [
  // {
  //   title: 'Cases',
  //   to: 'cases',
  // },
  {
    title: 'Advantage',
    to: 'advantage',
  },
  {
    title: 'Earn with Pad',
    to: 'earn',
  },
  {
    title: 'Projects',
    to: 'projects',
  },
  {
    title: 'Reviews',
    to: 'reviews',
  },
  {
    title: 'About MMPro',
    to: 'about',
  },
]

const footerLinks = {
  'Main': [
    {
      title: 'Apply for IDO',
      href: 'https://forms.gle/jZeXrnRW8VjKV7VDA',
    },
    {
      title: 'Referral Program',
      href: 'https://t.me/MMPROWL_bot',
    },
    {
      title: 'Farming',
      href: 'https://farming.marketmaking.pro/',
    },
  ],
  'Token Info': [
    {
      title: 'CoinGecko',
      href: 'https://www.coingecko.com/en/coins/market-making-pro',
    },
    {
      title: 'CoinMarketCap',
      href: 'https://coinmarketcap.com/currencies/mmpro-token/',
    },
    {
      title: 'PancakeSwap',
      href: 'https://pancakeswap.finance/swap?outputCurrency=0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70',
    },
    {
      title: 'Certik',
      href: 'https://skynet.certik.com/projects/mmpro-token',
    },
  ],
}

const BegginerLanding = () => {
  return (
    <Container>
      <Header links={links} />
      <Main />
      {/* <Element name={'cases'}> */}
      {/*   <XFactor /> */}
      {/* </Element> */}
      <HowWorkLaunchpad />
      <Element name={'advantage'}>
        <Benefits />
      </Element>
      <Element name={'earn'}>
        <HowEarn />
      </Element>
      <HowWork />
      <Element name={'about'}>
        <About />
      </Element>
      <Element name={'projects'}>
        <Projects />
      </Element>
      <Element name={'reviews'}>
        <Reviews />
      </Element>
      <TokenDetails />
      <Utilities />
      <Footer links={footerLinks} />
    </Container>
  )
}

export default BegginerLanding
