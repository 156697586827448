import Allocation from './Assets/Allocation'
import Coin from './Assets/Coin'
import Crosshair from './Assets/Crosshair'
import FileList from './Assets/FileList'
import Funds from './Assets/Funds'
import Playlist from './Assets/Playlist'
import PriceTag from './Assets/PriceTag'
import Search from './Assets/Search'

export const howEarnCards = [
  {
    id: 1,
    icon: Search,
    title: 'Startup Search',
    subtitle: 'The launchpad searches for a promising startup',
  },
  {
    id: 2,
    icon: Crosshair,
    title: 'Selection of Top Project',
    subtitle: 'The launchpad selects a top-notch project',
  },
  {
    id: 3,
    icon: Playlist,
    title: 'Project Listing on Launchpad',
    subtitle: 'The project is listed on the launchpad',
  },
  {
    id: 4,
    icon: FileList,
    title: 'Whitelist Participation',
    subtitle: 'Adding oneself to the Whitelist',
  },
  {
    id: 5,
    icon: Allocation,
    title: 'Allocation',
    subtitle: 'Receiving a signal and participating in the allocation',
  },
  {
    id: 6,
    icon: Coin,
    title: 'Token Purchase',
    subtitle: 'Purchasing tokens at a favorable price',
  },
  {
    id: 7,
    icon: Funds,
    title: 'Exchange Listing',
    subtitle: 'The project is listed on the exchange',
  },
  {
    id: 8,
    icon: PriceTag,
    title: 'Token Sale',
    subtitle: 'Selling tokens with a return of X3-X20',
  },
]
