import React, { ReactNode } from 'react'
import { Tooltip as AntdTooltip } from 'antd'
import styled from 'styled-components'

type TooltipProps = {
  title: ReactNode | string
  children: ReactNode
}

const StyledTooltip = styled(AntdTooltip)`
  .ant-tooltip-inner {
    padding: 18px;
    border-radius: 16px;
    color: #181833;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border: 1px solid #BABAC2;
  }
`

const Tooltip = (props: TooltipProps) => {
  const { children, title } = props
  return (
    <StyledTooltip
      color={'#fff'}
      getPopupContainer={triggerNode => triggerNode}
      title={title}
      arrow={false}
    >
      {children}
    </StyledTooltip>
  )
}

export default Tooltip
