import styled from 'styled-components'

export const CorporateElement = styled.div`
  width: 230px;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`
export const Year = styled.div`
  color: #181833;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`
export const Img = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
`
export const Description = styled.div`
  position: relative;
  color: #181833;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  padding-bottom: 12px;
`
export const InfoIconWrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  top: -15px;
  cursor: pointer;
`
export const Icons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
`
export const Features = styled.div`
  margin-top: 26px;
`
export const Feature = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  position: relative;

  ::before {
    position: absolute;
    content: "";

    max-width: 230px;
    width: 100%;
    height: 32px;

    right: 0;
    bottom: -8px;
    background: url("/images/underline.svg") no-repeat;
    background-size: contain;
  }
`
export const FeatureText = styled.span`
  max-width: 198px;
  padding-right: 10px;
  color: #181833;
  text-align: right;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

export const AboutWrap = styled.div`
  position: relative;
`
export const BgEllipseLeft = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("/images/about-bg-ellipse1.png") no-repeat;
  background-size: cover;
  pointer-events: none;
`
export const BgEllipseRight = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background: url("/images/about-bg-ellipse2.png") no-repeat;
  background-size: cover;
  pointer-events: none;
`

export const AboutContainer = styled.div``
export const AboutTitle = styled.div`
  color: #181833;
  font-family: Gilroy;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media screen and (max-width: 1180px) {
    font-size: 24px;
  }
`
export const AboutDescription = styled.div`
  margin-top: 17px;

  color: #181833;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }

  span {
    font-weight: 600;
  }
`
export const LabelCorporate = styled.div`
  margin-top: 15px;
  color: #181833;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }
`
export const Corporations = styled.div`
  margin-top: 23px;
  display: flex;
  gap: 40px;

  overflow-x: auto;
`
