import React from 'react'

const Simplicity = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#33CC66" />
      <path
        d="M15 10C15 7.92894 16.6789 6.25 18.75 6.25C20.8211 6.25 22.5 7.92894 22.5 10C22.5 10.4383 22.4248 10.859 22.2866 11.25H30C30.6904 11.25 31.25 11.8096 31.25 12.5V16.4075C31.25 16.794 31.0712 17.1587 30.7659 17.3954C30.4604 17.6323 30.0626 17.7144 29.6884 17.6181C29.3899 17.5412 29.0756 17.5 28.75 17.5C26.6789 17.5 25 19.1789 25 21.25C25 23.3211 26.6789 25 28.75 25C29.0756 25 29.3899 24.9587 29.6884 24.8819C30.0626 24.7856 30.4604 24.8677 30.7659 25.1045C31.0712 25.3414 31.25 25.706 31.25 26.0925V30C31.25 30.6904 30.6904 31.25 30 31.25H10C9.30965 31.25 8.75 30.6904 8.75 30V12.5C8.75 11.8096 9.30965 11.25 10 11.25H15.2134C15.0752 10.859 15 10.4383 15 10Z"
        fill="white"
      />
    </svg>
  )
}

export default Simplicity
