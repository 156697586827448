import React from 'react'
import { ProjectType } from '../../../../types/project'
import { Heading, Wrapper, ExtraInfo } from './styled'
import Timer from '../Common/Timer'
import { useUserInfo } from '../../../../hooks/useUserInfo'
import { getNumberFromBigInt } from '../../../../utils/formatBigInt'

type SalesStartInProps = {
  project: ProjectType
}

const SalesStartIn = ({ project }: SalesStartInProps) => {
  const { allocatedAmount } = useUserInfo(project.id)
  const allocatedInUSD = Number(allocatedAmount) * project.price || 0
  return (
    <Wrapper>
      <Heading>Sales starts in</Heading>
      <Timer dateInSeconds={project.saleOpen} />
      <ExtraInfo>
        Your allocation: {getNumberFromBigInt(allocatedAmount).toFixed(1)}{' '}
        {project.tokenName} ({allocatedInUSD.toFixed(1)} USD)
      </ExtraInfo>
    </Wrapper>
  )
}

export default SalesStartIn
