import React from 'react'

const Hacken = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
        fill="#3EF2D0"
      />
      <g clipPath="url(#clip0_333_294)">
        <mask
          id="mask0_333_294"
          maskUnits="userSpaceOnUse"
          x="8"
          y="12"
          width="28"
          height="28"
        >
          <path d="M36 12H8V40H36V12Z" fill="white" />
        </mask>
        <g mask="url(#mask0_333_294)">
          <path
            d="M34.1333 12V17.6H32.2667V12H30.4V17.6H28.5333V12H26.6667V17.6H17.3333V12H15.4667V17.6V19.4667H34.1333V21.3333H32.2667H30.4H13.6V12H11.7333V21.3333H9.86667V12H8V21.3333V23.2V32.5333H9.86667V26.9333H11.7333V32.5333H13.6V26.9333H15.4667V32.5333H17.3333V26.9333H26.6667V32.5333H28.5333V26.9333V25.0667H13.6H11.7333H9.86667V23.2H11.7333H13.6H30.4V32.5333H32.2667V23.2H34.1333V32.5333H36V23.2V21.3333V19.4667V17.6V12H34.1333Z"
            fill="#222223"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_333_294">
          <rect width="28" height="21" fill="white" transform="translate(8 12)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Hacken
