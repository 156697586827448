import React from 'react'

const Connect = () => {
  return (
    <svg
      width="180"
      height="73"
      viewBox="0 0 180 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_343_1839)">
        <rect x="105.845" width="74.155" height="72.2535" rx="10" fill="#33CC66" />
      </g>
      <path
        d="M142.793 18.3803V25.3094H140.863V34.0473L137.866 31.1079V31.1006H137.858H135.929V26.5712H135.669V31.1006H133.732V42.8874H135.669V49.2475H135.929V42.8874H137.866V35.9583L140.863 38.905V46.3154H142.793V55.1409H143.052V46.3154H144.989V38.8977L147.987 35.951V42.8874H149.924V49.2475H150.183V42.8874H152.113V31.1006H150.183V26.5712H149.924V31.1006H147.987V31.1079V31.1006L144.989 34.0473V25.3094H143.052V18.3803H142.793Z"
        fill="white"
      />
      <path
        d="M62.714 21.7268H5.71388V13.0868C5.71388 11.4962 6.98988 10.2068 8.56388 10.2068H59.8639C61.438 10.2068 62.714 11.4962 62.714 13.0868V21.7268ZM62.714 27.4868V59.1668C62.714 60.7574 61.438 62.0468 59.8639 62.0468H8.56388C6.98988 62.0468 5.71388 60.7574 5.71388 59.1668V27.4868H62.714ZM31.3639 41.8868V34.6868L18.5389 47.6468H48.4639V41.8868H31.3639Z"
        fill="#33CC66"
      />
      <path d="M62.5 39H85V33.5H106" stroke="#33CC66" />
      <defs>
        <filter
          id="filter0_b_343_1839"
          x="98.245"
          y="-7.6"
          width="89.355"
          height="87.4535"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.8" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_343_1839"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_343_1839"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Connect
