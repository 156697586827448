import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 44px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`
export const Title = styled.div`
  max-width: 528px;
  width: 100%;

  color: #181833;
  font-family: Gilroy;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`
export const CardsWrapper = styled.div`
  margin-top: 45px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 192px);
  gap: 32px;

  img {
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 4px;
    background: #3C6;
  }

  @media screen and (max-width: 676px) {
    display: flex;
    flex-direction: column;
    gap: 42px;
  }

`
