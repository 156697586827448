import styled from 'styled-components'
export const Wrapper = styled.div`
  width: 416px;
  padding: 34px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 20px;
  background: #FFF;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const Heading = styled.div`
    color: #181833;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
    opacity: 0.6;
`
export const ExtraInfo = styled.div`
  margin-top: 9px;

  color: #181833;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  opacity: 0.5;
`
