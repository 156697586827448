import React, { type ReactNode } from 'react'
import styles from './styles.module.scss'

type MobMenuTypeProps = {
  isMobMenuShown?: boolean
  toggleMobMenu?: () => void
  children?: ReactNode
}

const MobMenu = ({ isMobMenuShown, toggleMobMenu, children }: MobMenuTypeProps) => {
  return (
    <div className={styles.mob}>
      <div
        className={`${styles.mobBtn} ${isMobMenuShown ? styles.open : ''}`}
        onClick={toggleMobMenu}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {children}
    </div>
  )
}

export default MobMenu
