import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { WagmiProvider } from 'wagmi'
import { config } from './wagmi/config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './styles/normalize.scss'
import './styles/base.scss'
import './styles/fonts.css'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
    mutations: {
      gcTime: 0,
    },
  },
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </WagmiProvider>
  </BrowserRouter>,
)
