import React from 'react'

const Search = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M15 6C19.968 6 24 10.032 24 15C24 19.968 19.968 24 15 24C10.032 24 6 19.968 6 15C6 10.032 10.032 6 15 6ZM15 22C18.8675 22 22 18.8675 22 15C22 11.1325 18.8675 8 15 8C11.1325 8 8 11.1325 8 15C8 18.8675 11.1325 22 15 22ZM23.4853 22.0711L26.3137 24.8995L24.8995 26.3137L22.0711 23.4853L23.4853 22.0711Z"
        fill="white"
      />
    </svg>
  )
}

export default Search
