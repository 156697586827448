import React from 'react'

const UtilitiesStore = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 10V17C19 17.5523 18.5523 18 18 18H2C1.44772 18 1 17.5523 1 17V10H0V8L1 3H19L20 8V10H19ZM3 10V16H17V10H3ZM4 11H12V14H4V11ZM1 0H19V2H1V0Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default UtilitiesStore
