import React from 'react'

const UtilitiesMMaS = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0ZM10 9V6L6 10L10 14V11H14V9H10Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default UtilitiesMMaS
