import styled from 'styled-components'

export const Wrapper = styled.div<{ background: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px;
  width: 416px;
  height: 380px;
  background: ${p => p.background};
  border-radius: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 380px;
  }
`
export const QouteText = styled.div<{ color: string }>`
  color: ${p => p.color};
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; 
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
export const QuoteIcon = styled.div`
  z-index: 1;
  position: absolute;
  top: 60px;
  left: 25px;
`
export const MainText = styled.div<{ color: string }>`
  position: relative;
  z-index: 2;
  color: ${p => p.color};
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  @media screen and (max-width: 768px) {
   font-size: 14px;
  }
`
export const CompanyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`
export const CompanyTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`
export const CompanyName = styled.div<{ color: string }>`
  color: ${p => p.color};
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`
export const CompanyTeam = styled.div<{ color: string }>`
  color: ${p => p.color};
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`
