import React from 'react'
import { Wrapper, Title, Texts, Subtitle, CardsWrapper } from './styled'
import GenericCard from '../GenericCard'
import { launchpadCards } from './data'

const HowWorkLaunchpad = () => {
  return (
    <div className={'_container'}>
      <Wrapper>
        <Texts>
          <Title>How do launchpads usually work?</Title>
          <Subtitle>
            A launchpad is a platform where young companies (startups) work on new
            projects using blockchain technology and raise funds for their products.{' '}
            <span>
              Launchpads usually cover investors, projects and funds in one platform to
              provide early-stages allocation rounds.
            </span>
          </Subtitle>
        </Texts>
        <CardsWrapper>
          {launchpadCards.map(card => (
            <GenericCard
              key={card.id}
              icon={card.icon()}
              title={card.title}
              subtitle={card.subtitle}
            />
          ))}
        </CardsWrapper>
      </Wrapper>
    </div>
  )
}

export default HowWorkLaunchpad
