export const collapses = [
  {
    id: 1,
    title: 'What will I need to participate in the MMPro Launchpad?',
    text: '- You must have MMPRO tokens to participate in the pre-purchase of tokens. To participate in the purchase of tokens or shares, you need to transfer a certain number of MMPRO tokens to one of the allocation pools, they will be blocked for the period of sale, after the end of the sale you can withdraw them to your wallet.',
  },
  {
    id: 2,
    title: 'Which of the two liquidity pools should I participate in?',
    text: `- Depending on the number of purchased MMPRO , there will be two pools available for you to participate in the sale of shares or tokens. You can participate in one or more pools. The number of tokens or shares sold in the pools, will be distributed among all those who wish to participate according to the terms of participation.
	<br/>
	<strong>a. Limited </strong> - To participate in the pool, users need to block no more than 10 000 MMPRO . Allocation of the pool will be distributed among all participants.
	<br/>
	<strong>b. Unlimited </strong> - Users can use any number of MMPRO to participate in the pool, the allocation will be distributed depending on the number of tokens and the location time of the MMPRO. After allocation, users will have the opportunity to redeem the corresponding tokens or shares.
	`,
  },
  {
    id: 3,
    title: 'In which tokens is it possible to buy tokens or shares?',
    text: '- Buying tokens or shares is possible in  <strong>BUSD, USDT, USDC, DAI </strong> Tokens to redeem allocation, you must have in your wallet to make a payment. You must also have BNB to make transactions and pay commission.',
  },
  {
    id: 4,
    title: 'Within how long can I redeem an allocation?',
    text: '- There will be a certain amount of time to pay the tokens, if you do not redeem the allocation in time, it will be unavailable.',
  },
  {
    id: 5,
    title: 'How do I get my shares or tokens?',
    text: '- After you purchase tokens, they need to be branded on the blockchain. To do this, click on the Claim button and confirm the transaction in your wallet',
  },
  {
    id: 6,
    title: 'How do I get my MMPRO tokens back?',
    text: '- Then you can return the MMPRO tokens to your wallet by pressing the LaunchpadFinished MMPRO button.',
  },
]
