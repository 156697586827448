import React from 'react'

const Coin = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M16.0049 8.00269C22.08 8.00269 27.0049 10.689 27.0049 14.0026V18.0026C27.0049 21.3164 22.08 24.0026 16.0049 24.0026C10.0382 24.0026 5.18114 21.4114 5.00957 18.1796L5.00488 18.0026V14.0026C5.00488 10.689 9.92975 8.00269 16.0049 8.00269ZM16.0049 20.0026C12.2844 20.0026 8.99537 18.9952 7.00466 17.4531L7.00488 18.0026C7.00488 19.8848 10.8875 22.0026 16.0049 22.0026C21.0156 22.0026 24.8426 19.9722 24.9999 18.1206L25.0049 18.0026L25.0061 17.4523C23.0155 18.9948 19.726 20.0026 16.0049 20.0026ZM16.0049 10.0027C10.8875 10.0027 7.00488 12.1205 7.00488 14.0026C7.00488 15.8848 10.8875 18.0026 16.0049 18.0026C21.1223 18.0026 25.0049 15.8848 25.0049 14.0026C25.0049 12.1205 21.1223 10.0027 16.0049 10.0027Z"
        fill="white"
      />
    </svg>
  )
}

export default Coin
