import React from 'react'

const MetaMask = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6478 2.67432L13.1525 8.24117L14.5386 4.9568L20.6478 2.67432Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.34461 2.67432L10.7796 8.2939L9.46137 4.9568L3.34461 2.67432ZM17.951 15.5783L15.9548 18.6367L20.226 19.8118L21.4538 15.6461L17.951 15.5783ZM2.55365 15.6461L3.77399 19.8118L8.04518 18.6367L6.04894 15.5783L2.55365 15.6461Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.80416 10.4105L6.61395 12.2109L10.855 12.3992L10.7043 7.84179L7.80416 10.4105ZM16.1883 10.4105L13.2505 7.78906L13.1526 12.3992L17.3861 12.2109L16.1883 10.4105ZM8.04521 18.6365L10.5914 17.3936L8.39173 15.6761L8.04521 18.6365ZM13.4011 17.3936L15.9548 18.6365L15.6008 15.6761L13.4011 17.3936Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9548 18.6365L13.4012 17.3936L13.6045 19.0583L13.582 19.7589L15.9548 18.6365ZM8.04523 18.6365L10.4181 19.7589L10.403 19.0583L10.5914 17.3936L8.04523 18.6365Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04518 18.6365L8.40677 15.5782L6.04895 15.646L8.04518 18.6365ZM15.5932 15.5782L15.9548 18.6365L17.951 15.646L15.5932 15.5782ZM17.386 12.2109L13.1525 12.3993L13.5442 14.5763L14.1695 13.2656L15.6761 13.951L17.386 12.2109ZM8.33144 13.951L9.83803 13.2656L10.4557 14.5763L10.855 12.3993L6.61392 12.2109L8.33144 13.951Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4558 14.5764L8.33148 13.9511L9.83054 13.2656L10.4558 14.5764ZM13.5368 14.5764L14.162 13.2656L15.6686 13.9511L13.5368 14.5764Z"
        fill="#233447"
        stroke="#233447"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.61395 12.2109L8.39173 15.6761L8.33147 13.951L6.61395 12.2109ZM15.6761 13.951L15.6008 15.6761L17.3861 12.2109L15.6761 13.951ZM10.855 12.3993L10.4558 14.5763L10.9529 17.145L11.0659 13.7627L10.855 12.3993ZM13.1526 12.3993L12.9492 13.7552L13.0396 17.145L13.5443 14.5763L13.1526 12.3993Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5442 14.5764L13.0395 17.1451L13.4011 17.3937L15.6007 15.6762L15.6761 13.9512L13.5442 14.5764ZM8.33142 13.9512L8.39168 15.6762L10.5913 17.3937L10.9529 17.1451L10.4557 14.5764L8.33142 13.9512Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.582 19.7591L13.6045 19.0586L13.4162 18.8928H10.5763L10.403 19.0586L10.4181 19.7591L8.04523 18.6367L8.87385 19.3147L10.5537 20.4823H13.4388L15.1262 19.3147L15.9548 18.6367L13.582 19.7591Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4011 17.3936L13.0395 17.145H10.9529L10.5913 17.3936L10.403 19.0584L10.5763 18.8927H13.4162L13.6045 19.0584L13.4011 17.3936Z"
        fill="#161616"
        stroke="#161616"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9642 8.60275L21.6045 5.52931L20.6479 2.67432L13.4012 8.05285L16.1884 10.4107L20.1281 11.5632L21.0019 10.5463L20.6253 10.2751L21.2279 9.72516L20.7609 9.36358L21.3635 8.90407L20.9642 8.60275ZM2.39551 5.52931L3.03581 8.60275L2.62903 8.90407L3.23167 9.36358L2.77216 9.72516L3.37479 10.2751L2.99814 10.5463L3.86443 11.5632L7.80417 10.4107L10.5914 8.05285L3.34466 2.67432L2.39551 5.52931Z"
        fill="#763D16"
        stroke="#763D16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.128 11.5629L16.1883 10.4104L17.386 12.2108L15.6007 15.6759L17.951 15.6458H21.4538L20.128 11.5629ZM7.80412 10.4104L3.86438 11.5629L2.55365 15.6458H6.04894L8.39169 15.6759L6.61391 12.2108L7.80412 10.4104ZM13.1525 12.3991L13.4011 8.05259L14.5461 4.95654H9.46137L10.5913 8.05259L10.855 12.3991L10.9454 13.7701L10.9529 17.1449H13.0395L13.0546 13.7701L13.1525 12.3991Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MetaMask
