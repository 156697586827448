import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 895px) {
    flex-direction: column;
    gap: 10px;
  }
`
export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  @media screen and (max-width: 895px) {
    gap: 10px;
  }
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  
  span {
    color: #3C6;
  }
  
  @media screen and (max-width: 895px) {
    font-size: 32px;
    text-align: left;
  }
`
export const Subtitle = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media screen and (max-width: 895px) {
    font-size: 16px;
    text-align: left;
  }
`
export const Image = styled.img`
  width: 655px;
  height: 660px;

  @media screen and (max-width: 895px) {
    width: 300px;
    height: 365px;
  }
`
export const ButtonWrapper = styled.div`
  width: 100%;
  button {
    @media screen and (max-width: 895px) {
      width: 100%;
    }
  }
`
