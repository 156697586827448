import React from 'react'

const MetaMask = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3638 15.5353L16.9496 14.1211L18.3638 12.7069C20.3164 10.7543 20.3164 7.58848 18.3638 5.63586C16.4112 3.68323 13.2453 3.68323 11.2927 5.63586L9.87849 7.05007L8.46428 5.63586L9.87849 4.22164C12.6122 1.48797 17.0443 1.48797 19.778 4.22164C22.5117 6.95531 22.5117 11.3874 19.778 14.1211L18.3638 15.5353ZM15.5353 18.3638L14.1211 19.778C11.3875 22.5116 6.95531 22.5116 4.22164 19.778C1.48797 17.0443 1.48797 12.6121 4.22164 9.8785L5.63585 8.46428L7.05007 9.8785L5.63585 11.2927C3.68323 13.2453 3.68323 16.4111 5.63585 18.3638C7.58847 20.3164 10.7543 20.3164 12.7069 18.3638L14.1211 16.9495L15.5353 18.3638ZM14.8282 7.75718L16.2425 9.17139L9.17139 16.2424L7.75717 14.8282L14.8282 7.75718Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default MetaMask
