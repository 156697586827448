import React from 'react'
import { Wrapper, Title, CardsWrapper } from './styled'
import GenericCard from '../GenericCard'
import { howEarnCards } from './data'

const HowEarn = () => {
  return (
    <div className={'_container'}>
      <Wrapper>
        <Title>How can you earn money with the MMPRO pad?</Title>
        <CardsWrapper>
          {howEarnCards.map(card => (
            <GenericCard
              key={card.id}
              icon={card.icon()}
              title={card.title}
              subtitle={card.subtitle}
            />
          ))}
        </CardsWrapper>
      </Wrapper>
    </div>
  )
}

export default HowEarn
