import React from 'react'
import {
  Heading,
  ImagesWrapper,
  Img,
  RowWrapper,
  RowsWrapper,
  Text,
  Wrapper,
} from './styled'

import USDTIcon from '../../../Assets/Usdt.png'
import USDCIcon from '../../../Assets/Usdc.png'
import DaiIcon from '../../../Assets/Dai.png'
import { formatDate } from '../../../../../utils/formatDate'
import { ProjectType } from '../../../../../types/project'

interface Props {
  project: ProjectType
}

const Info = ({ project }: Props) => {
  const subStartTime = new Date(project.subStartTime * 1000)
  const subEndTime = new Date(project.subEndTime * 1000)
  const saleStartTime = new Date(project.saleOpen * 1000)
  const saleCloseTime = new Date(project.saleClose * 1000)
  const claimTime = new Date(project.claim * 1000)
  const renderTbaOrValue = (date: Date) => {
    if (project.tba) return 'To be announced'
    return formatDate(date)
  }

  return (
    <Wrapper>
      <RowsWrapper>
        <RowWrapper>
          <Heading>Pay in</Heading>
          <ImagesWrapper>
            <Img src={USDTIcon} alt="icon" />
            <Img src={USDCIcon} alt="icon" />
            <Img src={DaiIcon} alt="icon" />
          </ImagesWrapper>
        </RowWrapper>
        <RowWrapper>
          <Heading>Public Sale Share Price</Heading>
          <Text>
            1 ${project.tokenName} = ${project.price_per_share} USD
          </Text>
        </RowWrapper>
        <RowWrapper>
          <Heading>Subscription starts</Heading>
          <Text>{renderTbaOrValue(subStartTime)}</Text>
        </RowWrapper>
        <RowWrapper>
          <Heading>Subscription ends</Heading>
          <Text>{renderTbaOrValue(subEndTime)}</Text>
        </RowWrapper>
        <RowWrapper>
          <Heading>Purchase opens</Heading>
          <Text>{renderTbaOrValue(saleStartTime)}</Text>
        </RowWrapper>
        <RowWrapper>
          <Heading>Purchase closes</Heading>
          <Text>{renderTbaOrValue(saleCloseTime)}</Text>
        </RowWrapper>
        <RowWrapper>
          <Heading>Claimable</Heading>
          <Text>{renderTbaOrValue(claimTime)}</Text>
        </RowWrapper>
      </RowsWrapper>
    </Wrapper>
  )
}

export default Info
