import WowSummit from './Assets/WowSummit'
import Ivend from './Assets/Ivend'
import Farcana from './Assets/Farcana'
import Gate from './Assets/Gate'
import Hacken from './Assets/Hacken'
import { ReactNode } from 'react'

export type Review = {
  id: number
  textColor: string
  quotesColor: string
  backgroundColor: string
  icon: () => ReactNode
  companyName: string
  mainText: string
}
export const reviewsCards: Review[] = [
  {
    id: 1,
    textColor: '#000',
    quotesColor: '#DEDEDE',
    backgroundColor: '#F8F8F8',
    icon: WowSummit,
    companyName: 'WOW Summit',
    mainText:
      'We would like to express our sincere gratitude to the MMPro Launchpad team for their incredible contribution and professionalism in organizing our WOW Summit events. Your participation in the WOW Summit has been invaluable, and we were impressed by your ability to manage even the most complex aspects of the organization.',
  },
  {
    id: 2,
    textColor: '#fff',
    quotesColor: '#7068DC',
    backgroundColor: '#3427E6',
    icon: Ivend,
    companyName: 'ivendPay',
    mainText:
      'We at ivendPay would like to express our sincere gratitude and appreciation to the MMPRO Launchpad team for their outstanding work and professionalism during our launchpad. Thanks to their efforts, we successfully closed a collection of $300,000, which was a significant step in the development of our project.',
  },
  {
    id: 3,
    textColor: '#000',
    quotesColor: '#E5E5E5',
    backgroundColor: '#F8F8F8',
    icon: Farcana,
    companyName: 'Farcana',
    mainText:
      'We would like to express our boundless gratitude to the MMPro Launchpad team for their outstanding contribution to our rapid development and successful fundraising efforts for the Farcana project.',
  },
  {
    id: 4,
    textColor: '#fff',
    quotesColor: '#17E6A1',
    backgroundColor: '#2354E6',
    icon: Gate,
    companyName: 'Gate',
    mainText:
      'We would like to express our deep gratitude to the MMPro Launchpad team for their professionalism and support in placing high-liquidity assets on the Gate exchange.',
  },
  {
    id: 5,
    textColor: '#fff',
    quotesColor: '#0CAF90',
    backgroundColor: '#222223',
    icon: Hacken,
    companyName: 'Hacken',
    mainText:
      'We would like to express our deep gratitude to the MMPro Launchpad team for their professionalism and support in placing high-liquidity assets on the Gate exchange.',
  },
]
