import React from 'react'

const FileList = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#33CC66" />
      <path
        d="M24 26H8C7.44772 26 7 25.5523 7 25V7C7 6.44772 7.44772 6 8 6H24C24.5523 6 25 6.44772 25 7V25C25 25.5523 24.5523 26 24 26ZM23 24V8H9V24H23ZM12 11H20V13H12V11ZM12 15H20V17H12V15ZM12 19H20V21H12V19Z"
        fill="white"
      />
    </svg>
  )
}

export default FileList
