import React, { useEffect, useRef, useState } from 'react'
import {
  ModalMenuBlock,
  ExtraMenuBlock,
  MobMenuBlock,
  LogoBlock,
  MenuBlock,
  MenuElement,
  DesktopBlock,
  HeaderContainer,
  WalletsWrapper,
  WalletItem,
  MockBlock,
  MobileConnectWallet,
} from './styled'
import Button from '../UIKit/Button/Button'
import MobMenu from '../UIKit/MobMenu/MobMenu'
import { Modal } from '../UIKit/Modal/Modal'
import { Link } from 'react-scroll'
import {
  Connector,
  CreateConnectorFn,
  useAccount,
  useConnect,
  useDisconnect,
} from 'wagmi'
import { isMobile } from 'react-device-detect'
import MetaMask from './Assets/MetaMask'
import WalletConnect from './Assets/WalletConnect'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { useNavigate } from 'react-router-dom'

type ElementType = {
  title: string
  to: string
}

type HeaderProps = {
  links?: ElementType[]
}
const Header = ({ links }: HeaderProps) => {
  const [isMobMenuShown, setIsMobMenuShown] = useState(false)
  const [isConnectActive, setIsConnectActive] = useState(false)
  const { connect, connectors } = useConnect()
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const walletConnectRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const filteredConnectors = isMobile
    ? connectors.filter(connector => connector.id !== 'io.metamask')
    : connectors
  const toggleMobMenu = () => {
    setIsMobMenuShown(prev => !prev)
  }
  const toggleConnectWallet = () => {
    setIsConnectActive(!isConnectActive)
  }
  const connectWallet = (connector: CreateConnectorFn | Connector) => {
    connect({ connector })
    setIsConnectActive(false)
    setIsMobMenuShown(false)
  }

  useEffect(() => {
    const body = document.body
    body.style.overflow = isMobMenuShown ? 'hidden' : ''
    return () => {
      body.style.overflow = ''
    }
  }, [isMobMenuShown])

  const filteredConnectorsArray = filteredConnectors.map(connector => ({
    name: connector.name,
    id: connector.id,
    connector: connector,
    LogoComponent: connector.name === 'MetaMask' ? MetaMask : WalletConnect,
  }))

  useOnClickOutside(walletConnectRef, () => setIsConnectActive(false))

  return (
    <div className="_container">
      <HeaderContainer>
        <LogoBlock onClick={() => navigate('/')}>
          <img src="/images/Logo.svg" alt="logo" />
        </LogoBlock>
        {links && (
          <>
            <MenuBlock>
              {links.map((el, index) => {
                return (
                  <MenuElement key={index}>
                    {el.to.startsWith('http') ? (
                      <a href={el.to} target="_blank" rel="noopener noreferrer">
                        {el.title}
                      </a>
                    ) : (
                      <Link to={el.to} offset={50} duration={200}>
                        {el.title}
                      </Link>
                    )}
                  </MenuElement>
                )
              })}
              <MenuElement>
                <a href={'/guide.pdf'} target="_blank" rel="noreferrer">
                  How it works
                </a>
              </MenuElement>
            </MenuBlock>
            <ExtraMenuBlock>
              <DesktopBlock>
                <Button type={'connect'} onClick={toggleConnectWallet}>
                  {address
                    ? `${address.slice(0, 5)}...${address.slice(38, address.length)}`
                    : 'Connect'}
                </Button>
                <WalletsWrapper isActive={isConnectActive} ref={walletConnectRef}>
                  <MockBlock />
                  {address ? (
                    <WalletItem onClick={() => disconnect()}>Disconnect</WalletItem>
                  ) : (
                    <>
                      {filteredConnectorsArray.map(connector => (
                        <WalletItem
                          key={connector.id}
                          onClick={() => connectWallet(connector.connector)}
                        >
                          <connector.LogoComponent />
                          {connector.name}
                        </WalletItem>
                      ))}
                    </>
                  )}
                </WalletsWrapper>
              </DesktopBlock>
              <MobMenuBlock>
                <MobMenu toggleMobMenu={toggleMobMenu} isMobMenuShown={isMobMenuShown} />
              </MobMenuBlock>
              {isMobMenuShown && (
                <Modal>
                  <ModalMenuBlock>
                    {links.map((el, index) => {
                      return (
                        <MenuElement key={index}>
                          {el.to.startsWith('http') ? (
                            <a href={el.to} target="_blank" rel="noopener noreferrer">
                              {el.title}
                            </a>
                          ) : (
                            <Link
                              to={el.to}
                              offset={50}
                              duration={200}
                              onClick={() => setIsMobMenuShown(false)}
                            >
                              {el.title}
                            </Link>
                          )}
                        </MenuElement>
                      )
                    })}
                    <MenuElement>
                      <a href={'/guide.pdf'} target="_blank" rel="noreferrer">
                        How it works
                      </a>
                    </MenuElement>
                  </ModalMenuBlock>
                  <MobileConnectWallet>
                    <Button type={'connect'} onClick={toggleConnectWallet}>
                      {address
                        ? `${address.slice(0, 5)}...${address.slice(38, address.length)}`
                        : 'Connect'}
                    </Button>
                    <WalletsWrapper isActive={isConnectActive} ref={walletConnectRef}>
                      <MockBlock />
                      {address ? (
                        <WalletItem onClick={() => disconnect()}>Disconnect</WalletItem>
                      ) : (
                        <>
                          {filteredConnectors.map(connector => (
                            <WalletItem
                              key={connector.id}
                              onClick={() => connectWallet(connector)}
                            >
                              {connector.name}
                            </WalletItem>
                          ))}
                        </>
                      )}
                    </WalletsWrapper>
                  </MobileConnectWallet>
                </Modal>
              )}
            </ExtraMenuBlock>
          </>
        )}
      </HeaderContainer>
    </div>
  )
}

export default Header
