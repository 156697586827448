import React from 'react'

const BackArrow = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8284 12.5007L15.7782 17.4504L14.364 18.8646L8 12.5007L14.364 6.13672L15.7782 7.55093L10.8284 12.5007Z"
        fill="#33CC66"
      />
    </svg>
  )
}

export default BackArrow
