export const corporations = [
  {
    id: 1,
    year: '2017',
    imgSrc: '/images/corporate/mmpro.svg',
    desc: 'Market Maker for projects',
    knowMore: '',
    global: 'https://marketmaking.pro/',
    twitter: 'https://x.com/MarketmakingX',
    tg: 'https://t.me/marketmakingpro',
    features: [
      'Market-making services for projects',
      'Listing support on centralized exchanges',
      'Consulting services and investments in web3 industry',
      'Launchpad for crypto projects',
    ],
    tooltipText:
      'A leading market maker in the crypto industry, managing the liquidity of companies with a total capitalization of USD 3.5 billion. Provides services for more than 500 projects since 2017 and operates on 62 exchanges.',
  },
  {
    id: 2,
    year: '2020',
    imgSrc: '/images/corporate/wow.svg',
    desc: 'Offline fintech conference',
    knowMore: '',
    global: 'https://hongkong2024.wowsummit.net/',
    twitter: 'https://twitter.com/wowsummitworld',
    tg: 'https://t.me/wowsummitworld',
    features: [
      'Large-scale event organizing',
      'FinTech-related networking',
      'Building GR (Government Relations)',
    ],
    tooltipText:
      'Series of FinTech conferences for decision makers in more than 5 countries around the world.\n' +
      'Supported by governmental authorities of UAE and Hong Kong.\n' +
      'The total number of participants is more than 20 thousand.',
  },
  {
    id: 3,
    year: '2020',
    imgSrc: '/images/corporate/mmprotrust.svg',
    desc: 'OTC and pre-IPO investor',
    knowMore: '',
    global: 'https://mmprotrust.com/',
    twitter: 'https://twitter.com/mmprotrust',
    tg: 'https://t.me/mmprotrust',
    features: [
      'Late stage investments',
      'WhiteLabel solution for structuring pre-IPO deals',
    ],
    tooltipText:
      'An investment company for purchasing secondary shares of late-stage venture capital companies. The portfolio under management consists of more than 20 companies with a total management volume of more than $150 million.',
  },
  {
    id: 4,
    year: '2021',
    imgSrc: '/images/corporate/zimabank.svg',
    desc: 'Neo-banking ecosystem',
    knowMore: '',
    global: 'https://zimabank.com/',
    twitter: 'https://twitter.com/zimabank',
    tg: 'https://t.me/zimabank',
    features: [
      'Corporate IBANs opening and management',
      'Payment processing and bank acquiring',
      'OTC deals structuring',
    ],
    tooltipText:
      'A new generation European bank for international business with a focus on traditional and cryptocurrency solutions, SEPA-connected.\n' +
      'Certified by EUPL in accordance with\n' +
      'FATCA, compliant with FINTRAC.',
  },
]
