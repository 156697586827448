import React from 'react'

const Buy = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0174 25.6674L3.04688 12.6969L8.70372 7.04004L21.6743 20.0106H82.6412C84.8504 20.0106 86.6412 21.8015 86.6412 24.0106C86.6412 24.3999 86.5844 24.7871 86.4728 25.16L76.8728 57.16C76.3652 58.852 74.8076 60.0108 73.0412 60.0108H24.0174V68.0108H68.0176V76.0108H20.0174C17.8083 76.0108 16.0174 74.2196 16.0174 72.0108V25.6674ZM22.0174 92.0108C18.7037 92.0108 16.0174 89.3244 16.0174 86.0108C16.0174 82.6968 18.7037 80.0108 22.0174 80.0108C25.3312 80.0108 28.0174 82.6968 28.0174 86.0108C28.0174 89.3244 25.3312 92.0108 22.0174 92.0108ZM70.0176 92.0108C66.7036 92.0108 64.0176 89.3244 64.0176 86.0108C64.0176 82.6968 66.7036 80.0108 70.0176 80.0108C73.3312 80.0108 76.0176 82.6968 76.0176 86.0108C76.0176 89.3244 73.3312 92.0108 70.0176 92.0108Z"
        fill="#33CC66"
      />
      <path
        d="M48.1435 19.3846L48.1435 27.7363L50.2751 27.7363L50.2751 38.2681L53.5873 34.7253L53.5873 34.7165L53.5955 34.7165L55.7271 34.7165L55.7271 29.2571L56.0141 29.2571L56.0141 34.7165L58.1539 34.7165L58.1539 48.9231L56.0141 48.9231L56.0141 56.589L55.7271 56.589L55.7271 48.9231L53.5873 48.9231L53.5873 40.5714L50.2751 44.1231L50.2751 53.0549L48.1435 53.0549L48.1435 63.6923L47.8566 63.6923L47.8566 53.0549L45.7167 53.0549L45.7167 44.1143L42.4045 40.5626L42.4045 48.9231L40.2647 48.9231L40.2647 56.589L39.9778 56.589L39.9778 48.9231L37.8462 48.9231L37.8462 34.7165L39.9778 34.7165L39.9778 29.2571L40.2647 29.2571L40.2647 34.7165L42.4045 34.7165L45.7167 38.2681L45.7167 27.7363L47.8565 27.7363L47.8565 19.3846L48.1435 19.3846Z"
        fill="white"
      />
      <path
        d="M48.1435 19.3846L48.1435 27.7363L50.2751 27.7363L50.2751 38.2681L53.5873 34.7253L53.5873 34.7165L53.5955 34.7165L55.7271 34.7165L55.7271 29.2571L56.0141 29.2571L56.0141 34.7165L58.1539 34.7165L58.1539 48.9231L56.0141 48.9231L56.0141 56.589L55.7271 56.589L55.7271 48.9231L53.5873 48.9231L53.5873 40.5714L50.2751 44.1231L50.2751 53.0549L48.1435 53.0549L48.1435 63.6923L47.8566 63.6923L47.8566 53.0549L45.7167 53.0549L45.7167 44.1143L42.4045 40.5626L42.4045 48.9231L40.2647 48.9231L40.2647 56.589L39.9778 56.589L39.9778 48.9231L37.8462 48.9231L37.8462 34.7165L39.9778 34.7165L39.9778 29.2571L40.2647 29.2571L40.2647 34.7165L42.4045 34.7165L45.7167 38.2681L45.7167 27.7363L47.8565 27.7363L47.8565 19.3846L48.1435 19.3846Z"
        stroke="white"
      />
    </svg>
  )
}

export default Buy
