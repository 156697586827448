import { toast } from 'react-toastify'
import { toastConfig } from '../config/toast'
export const showPositiveToast = (content: string) => {
  toast.success(content, {
    position: 'top-right',
    ...toastConfig,
  })
}
export const showNegativeToast = (errorMessage: string, isCustomMessage = false) => {
  const getErrorText = () => {
    if (isCustomMessage) return errorMessage

    if (errorMessage.includes('User denied transaction')) {
      return 'User denied the transaction.'
    } else if (errorMessage.includes('insufficient funds')) {
      return 'Insufficient funds to complete the transaction.'
    } else {
      return `An error occurred while processing the transaction.`
    }
  }

  toast.error(getErrorText(), {
    position: 'top-right',
    ...toastConfig,
  })
}
