import React from 'react'

type IconProps = {
  rotate: number
}

const Checkmark = ({ rotate }: IconProps) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)`, translate: 'all .3s ease' }}
    >
      <g opacity={'0.5'}>
        <path
          d="M7.00063 5.1714L11.9504 0.22168L13.3646 1.63589L7.00063 7.9999L0.636719 1.63589L2.05093 0.22168L7.00063 5.1714Z"
          fill="#191934"
        />
      </g>
    </svg>
  )
}

export default Checkmark
