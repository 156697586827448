import React from 'react'

const Security = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#33CC66" />
      <path
        d="M20 6.25L30.2711 8.53247C30.8431 8.65957 31.25 9.16684 31.25 9.75271V22.2361C31.25 24.7437 29.9967 27.0855 27.9102 28.4765L20 33.75L12.0898 28.4765C10.0033 27.0855 8.75 24.7437 8.75 22.2361V9.75271C8.75 9.16684 9.15691 8.65957 9.72884 8.53247L20 6.25ZM20 13.75C18.6193 13.75 17.5 14.8693 17.5 16.25C17.5 17.1753 18.0027 17.9832 18.7499 18.4155L18.75 23.75H21.25L21.2514 18.4148C21.9979 17.9823 22.5 17.1748 22.5 16.25C22.5 14.8693 21.3807 13.75 20 13.75Z"
        fill="white"
      />
    </svg>
  )
}

export default Security
