import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 304px;
    padding: 34px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 20px;
    background: #FFF;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const RowsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
export const RowWrapper = styled.div``
export const Heading = styled.div`
    color: #181833;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
    opacity: 0.6;
`
export const Text = styled.div`
    color: #181833;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
`
export const ImagesWrapper = styled.div`
    margin-top: 3px;
    display: flex;
    gap: 8px;
`
export const Img = styled.img`
    width: 24px;
    height: 24px;
`
