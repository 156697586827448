import { ProjectType, ProjectStatus } from '../../../types/project'

export const getProjectStatus = (project: ProjectType) => {
  const dateNow = Date.now()
  const subStartTime = project.subStartTime * 1000
  const subEndTime = project.subEndTime * 1000
  const saleStartTime = project.saleOpen * 1000
  const saleCloseTime = project.saleClose * 1000
  const claimTime = project.claim * 1000

  if (project.tba) {
    return ProjectStatus.TBA
  } else if (dateNow < subStartTime) {
    return ProjectStatus.SubscriptionStartIn
  } else if (dateNow >= subStartTime && dateNow < subEndTime) {
    return ProjectStatus.SubscriptionEndedIn
  } else if (dateNow >= subEndTime && dateNow < saleStartTime) {
    return ProjectStatus.SaleStartIn
  } else if (dateNow >= saleStartTime && dateNow < saleCloseTime) {
    return ProjectStatus.SaleEndedIn
  } else if (dateNow >= saleCloseTime && dateNow < claimTime) {
    return ProjectStatus.WaitingForClaim
  } else if (dateNow >= claimTime) {
    return ProjectStatus.LaunchpadFinished
  }

  return ProjectStatus.SubscriptionStartIn
}
