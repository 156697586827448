import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`
export const TitleAndCollapseWrapper = styled.div`
  padding-top: 127px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  order: 0;

  @media screen and (max-width: 1023px) {
    padding-top: 57px;
    order: 1;
    padding-top: 0px;
  }
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
`
export const MainImage = styled.img`
  order: 1;

  @media screen and (max-width: 1319px) {
    width: 50%;
    height: 50%;
  }
  @media screen and (max-width: 767px) {
    width: 320px;
    height: 460px;
    order: 0;
    align-self: center;
  }
`
