import React from 'react'
import { Wrapper, CardsWrapper, Title } from './styled'
import ReviewCard from './ReviewCard'
import { reviewsCards } from './data'

const Reviews = () => {
  return (
    <div className="_container">
      <Wrapper>
        <Title>Reviews</Title>
        <CardsWrapper>
          {reviewsCards.map(review => (
            <ReviewCard key={review.id} review={review} />
          ))}
        </CardsWrapper>
      </Wrapper>
    </div>
  )
}

export default Reviews
