import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 416px;
    padding: 34px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 20px;
    background: #FFF;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const Heading = styled.div`
    color: #181833;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
    opacity: 0.6;
`
export const ExtraInfo = styled.div`
  margin-top: 9px;

  color: #181833;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  opacity: 0.5;
`
export const Button = styled.button`
  height: 52px;
  width: 100%;
  margin-top: 16px;
  padding: 10px 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 44px;
  border: 1px solid #3C6;
  background: #3C6;

  color: #FFF;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 184%;
  cursor: pointer;
  transition: all .3s;
`
