import React from 'react'
import { Image, Title, Wrapper, Texts, Subtitle, ButtonWrapper } from './styled'
import Button from '../../../../components/UIKit/Button/Button'
import MainImg from './Assets/MainBanner.png'

const Main = () => {
  return (
    <div className={'_container'}>
      <Wrapper>
        <Texts>
          <Title>
            Coming Soon to the Launchpad: <span>Zima Bank!</span>
          </Title>
          <Subtitle>
            Be among the first to discover the unique opportunities of the new banking
            project. Join the whitelist for exclusive information.
          </Subtitle>
          <ButtonWrapper>
            <Button type={'base'}>
              <img src="/images/logo-btn.svg" alt="logo-btn" />
              Whitelist
            </Button>
          </ButtonWrapper>
        </Texts>
        <Image src={MainImg} />
      </Wrapper>
    </div>
  )
}

export default Main
