import styled from 'styled-components'

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;

  @media screen and (max-width: 1180px) {
    padding-top: 20px;
  }
`
export const LogoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 101;
  cursor: pointer;
  
  @media screen and (max-width: 767px) {
    width: 110px;
    height: 34px;
  }
`
export const MenuBlock = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 1180px) {
    display: none;
  }
`
export const MenuElement = styled.li`
  cursor: pointer;
  color: #181833;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 144.444%;
  letter-spacing: 0.2px;
`
export const ExtraMenuBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const DesktopBlock = styled.div`
  position: relative;
  @media screen and (max-width: 1180px) {
    display: none;
  }
`
export const MobMenuBlock = styled.div`
  position: relative;
  z-index: 101;
`
export const ModalMenuBlock = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;

  li {
    color: #181833;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144.444%;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
`
export const WalletsWrapper = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 30px;
  right: 0;
  display: ${p => (p.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  z-index: 1;
  
  :last-child {
    border-radius: 0 0 12px 12px;
  }
`
export const WalletItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px;
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #F4F4F9;
  height: 45px;
  cursor: pointer;
  width: 100%;
`
export const MockBlock = styled.div`
  height: 35px;
  width: 100%;
  background: #F4F4F9;
`
export const MobileConnectWallet = styled.div`
  position: relative;
`
