import { useAccount, useReadContract } from 'wagmi'
import { MainContractAbi } from '../contracts/MainContract'
import { contractsAddresses } from '../config/contractsAddresses'

export const usePoolInfo = (id: number) => {
  const poolProjectInfo = useReadContract({
    abi: MainContractAbi,
    address: contractsAddresses.main,
    functionName: 'poolInfo',
    args: [BigInt(id)],
  })

  const [
    owner,
    IToken,
    ITokenPrice,
    ITokenPerSec,
    depositLimit,
    startTimestamp,
    stakingEnd,
    purchaseEnd,
    lockupEnd,
    sharesTotal,
    lastRewardTimestamp,
    accLTokenPerShare,
    totalBought,
  ] = poolProjectInfo.data || []

  return {
    owner,
    IToken,
    ITokenPrice,
    ITokenPerSec,
    depositLimit,
    startTimestamp,
    stakingEnd,
    purchaseEnd,
    lockupEnd,
    sharesTotal,
    lastRewardTimestamp,
    accLTokenPerShare,
    totalBought,
    response: poolProjectInfo,
  }
}
