import { Bounce } from 'react-toastify'

export const toastConfig = {
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  theme: 'light',
  transition: Bounce,
}
