import React from 'react'
import styled from 'styled-components'
// import MarketData from './Components/MarketData'
import Token from './Components/Token'
import { Element } from 'react-scroll'
import About from '../../components/About'
import Projects from '../../components/Projects'
import Utilities from '../../components/Utilities'
import MainBanner from './Components/MainBanner'
import FAQ from './Components/FAQ'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;

  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`

const headerLinks = [
  {
    title: 'About MMPro',
    to: 'about',
  },
  {
    title: 'Projects',
    to: 'projects',
  },
  {
    title: 'Farming',
    to: 'https://farming.marketmaking.pro/',
  },
]

const footerLinks = {
  'Main': [
    {
      title: 'Apply for IDO',
      href: 'https://forms.gle/jZeXrnRW8VjKV7VDA',
    },
    {
      title: 'Referral Program',
      href: 'https://t.me/MMPROWL_bot',
    },
    {
      title: 'Farming',
      href: 'https://farming.marketmaking.pro/',
    },
  ],
  'Token Info': [
    {
      title: 'CoinGecko',
      href: 'https://www.coingecko.com/en/coins/market-making-pro',
    },
    {
      title: 'CoinMarketCap',
      href: 'https://coinmarketcap.com/currencies/mmpro-token/',
    },
    {
      title: 'PancakeSwap',
      href: 'https://pancakeswap.finance/swap?outputCurrency=0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70',
    },
    {
      title: 'Certik',
      href: 'https://skynet.certik.com/projects/mmpro-token',
    },
  ],
}

const ProfessionalLanding = () => {
  return (
    <Container>
      <Header links={headerLinks} />
      <MainBanner />
      {/* <MarketData /> */}
      <Token />
      <Element name="about">
        <About />
      </Element>
      <Element name="projects">
        <Projects />
      </Element>
      <Utilities />
      <Element name="faq">
        <FAQ />
      </Element>
      <Footer links={footerLinks} />
    </Container>
  )
}

export default ProfessionalLanding
