import React from 'react'

const Participation = () => {
  return (
    <svg
      width="80"
      height="78"
      viewBox="0 0 80 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_1650_44)">
        <rect width="79.5849" height="77.0717" rx="10" fill="#33CC66" />
      </g>
      <path
        d="M39.0243 18V26.0621H36.7795V36.2289L33.2914 32.8089V32.8004H33.2828H31.038V27.5303H30.7358V32.8004H28.4824V46.5145H30.7358V53.9147H31.038V46.5145H33.2914V38.4524L36.7795 41.8809V50.5031H39.0243V60.7717H39.3264V50.5031H41.5799V41.8724L45.0679 38.4439V46.5145H47.3213V53.9147H47.6235V46.5145H49.8683V32.8004H47.6235V27.5303H47.3213V32.8004H45.0679V32.8089V32.8004L41.5799 36.2289V26.0621H39.3264V18H39.0243Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_1650_44"
          x="-7.6"
          y="-7.6"
          width="94.784"
          height="92.2713"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.8" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1650_44"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1650_44"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Participation
