import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
`
export const Title = styled.div<{ type: string }>`
  color: ${p => (p.type === 'light' ? '#fff' : '#181833')};
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.023%;
`
export const Subtitle = styled.div<{ type: string }>`
  color: ${p => (p.type === 'light' ? '#fff' : '#181833')};
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
  
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`
