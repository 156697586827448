import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 90px;
  background: #F7F8FF;

  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`
export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
`
export const ReturnBackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  border-radius: 44px;
  border: 1px solid #3C6;
  background: #FFF;
  gap: 10px;
  cursor: pointer;
`
export const ReturnBackButtonText = styled.div`
  color: #3C6;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 184%;
`
