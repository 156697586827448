import React, { useState } from 'react'
import {
  Wrapper,
  ButtonDeposit,
  LaunchFinished,
  LaunchFinishedText,
  ExtraInfo,
} from './styled'

import DoubleCheckbox from '../../Assets/DoubleCheckbox'
import { ProjectType } from '../../../../types/project'
import { useUserInfo } from '../../../../hooks/useUserInfo'
import {
  DEFAULT_TOKEN_DECIMAL,
  getNumberFromBigInt,
} from '../../../../utils/formatBigInt'
import { useWriteContract } from 'wagmi'
import { MainContractAbi } from '../../../../contracts/MainContract'
import { contractsAddresses } from '../../../../config/contractsAddresses'
import { showNegativeToast, showPositiveToast } from '../../../../utils/toasts'
import Loader from '../../../../components/UIKit/Loader'
import { getTransactionReceipt } from '../../../../utils/getTransacationReceipt'

interface LaunchpadFinishedProps {
  project: ProjectType
}

const LaunchpadFinished = ({ project }: LaunchpadFinishedProps) => {
  const { writeContract } = useWriteContract()
  const {
    totalDeposit,
    purchasedAmount,
    response: userInfoResponse,
  } = useUserInfo(project.id)

  const [isWithdrawTxLoading, setIsWithdrawTxLoading] = useState(false)
  const [isPickUpTxLoading, setIsPickUpTxLoading] = useState(false)

  const onWithdrawClick = () => {
    window.open(`https://launchpad.marketmaking.pro/project/${project.id}`, '_blank')
  }
  const withdraw = () => {
    if (!totalDeposit) return
    setIsWithdrawTxLoading(true)
    console.log(totalDeposit)
    writeContract(
      {
        abi: MainContractAbi,
        address: contractsAddresses.main,
        functionName: 'withdraw',
        args: [BigInt(project.id), totalDeposit],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status == 'success') {
            userInfoResponse.refetch()
            setIsWithdrawTxLoading(false)
            showPositiveToast('Withdraw was successfully!')
          }
        },
        onError: error => {
          showNegativeToast(error.message)
          setIsWithdrawTxLoading(false)
        },
      },
    )
  }
  const pickUpTokens = () => {
    if (!purchasedAmount) return
    setIsPickUpTxLoading(true)
    writeContract(
      {
        abi: MainContractAbi,
        address: contractsAddresses.abi,
        functionName: 'pickUpTokens',
        args: [BigInt(0)],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status == 'success') {
            userInfoResponse.refetch()
            setIsPickUpTxLoading(false)
            showPositiveToast('Pick up tokens was successfully!')
          }
        },
        onError: error => {
          showNegativeToast(error.message)
          setIsPickUpTxLoading(false)
        },
      },
    )
  }

  return (
    <Wrapper>
      <LaunchFinished>
        <DoubleCheckbox />
        <LaunchFinishedText>Launchpad finished</LaunchFinishedText>
      </LaunchFinished>
      {getNumberFromBigInt(purchasedAmount) > 0 &&
        getNumberFromBigInt(totalDeposit) <= 0 &&
        project.isClaimable && (
          <ButtonDeposit onClick={pickUpTokens} isDisabled={isPickUpTxLoading}>
            {isPickUpTxLoading ? <Loader /> : 'Claim Rewards'}
          </ButtonDeposit>
        )}
      {getNumberFromBigInt(totalDeposit) > 0 && (
        <>
          <ButtonDeposit onClick={onWithdrawClick} isDisabled={isWithdrawTxLoading}>
            {isWithdrawTxLoading ? <Loader /> : 'Withdraw MMPro'}
          </ButtonDeposit>
          <ExtraInfo>
            Your deposit: {getNumberFromBigInt(totalDeposit).toFixed(1)} MMPRO
          </ExtraInfo>
        </>
      )}
      {getNumberFromBigInt(totalDeposit) <= 0 && (
        <>
          {getNumberFromBigInt(purchasedAmount) <= 1 ? (
            <ExtraInfo>Nothing to claim.</ExtraInfo>
          ) : (
            <ExtraInfo>
              You {project.isClaimable ? 'can claim' : 'will get'}{' '}
              {getNumberFromBigInt(purchasedAmount).toFixed(1)} ${project.tokenName}.
            </ExtraInfo>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default LaunchpadFinished
