import React, { type ReactNode } from 'react'
import styled from 'styled-components'

type ButtonType = 'base' | 'second' | 'connect' | undefined
type ButtonSizeType = 'base' | 'small' | undefined

interface ButtonStyledProps {
  buttonType?: ButtonType
  buttonSize?: ButtonSizeType
  styles?: React.CSSProperties
}

const ButtonStyled = styled.button<ButtonStyledProps>`
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  border-radius: 44px;
  z-index: 2;

  height: ${props =>
    (props.buttonSize === 'small' && '45px') || (props.buttonSize === 'base' && '65px')};
  padding: ${props =>
    (props.buttonSize === 'small' && '0 22px') ||
    (props.buttonSize === 'base' && '0 34px')};

  border: 2px solid ${props =>
    (props.buttonType === 'base' && '#FFF') ||
    (props.buttonType === 'connect' && '#3C6') ||
    (props.buttonType === 'second' && 'rgba(24, 24, 51, 0.30)')};

  background: ${props =>
    props.buttonType === 'connect'
      ? '#3C6'
      : props.buttonType === 'base'
        ? '#3C6'
        : 'transparent'};

  color: ${props =>
    (props.buttonType === 'base' && '#FFF') ||
    (props.buttonType === 'connect' && '#FFF') ||
    (props.buttonType === 'second' && '#181833')};

  font-family: Gilroy, serif;
  font-size: ${props =>
    (props.buttonSize === 'small' && '16px') || (props.buttonSize === 'base' && '20px')};

  @media screen and (max-width: 1023px) {
    height: ${props =>
      (props.buttonSize === 'small' && '45px') ||
      (props.buttonSize === 'base' && '50px')};
    padding: ${props =>
      (props.buttonSize === 'small' && '0 22px') ||
      (props.buttonSize === 'base' && '0 34px')};
    font-size: ${props =>
      (props.buttonSize === 'small' && '16px') ||
      (props.buttonSize === 'base' && '16px')};
  }
`

interface ButtonProps {
  type?: ButtonType
  styles?: React.CSSProperties
  children: ReactNode
  onClick?: () => void
  disabled?: boolean
  size?: ButtonSizeType
  link?: string
}

const Button = ({
  children,
  onClick,
  disabled,
  type,
  size = 'base',
  link,
  styles,
}: ButtonProps) => {
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank')
    } else if (onClick) {
      onClick()
    }
  }
  return (
    <ButtonStyled
      onClick={handleClick}
      disabled={disabled}
      buttonType={type}
      buttonSize={size}
      style={styles}
    >
      {children}
    </ButtonStyled>
  )
}

export default Button
