import React from 'react'
import { Heading, Wrapper, Text } from './styled'

const ToBeAnnounced = () => {
  return (
    <Wrapper>
      <Heading>Subscription starts in</Heading>
      <Text>To be announced (TBA)</Text>
    </Wrapper>
  )
}

export default ToBeAnnounced
