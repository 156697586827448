import styled from 'styled-components'
export const Wrapper = styled.div<{ width: number }>`
  z-index: 10;
  height: 64px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: #fff;
  position: relative;
  border-radius: 63px;
  color: #191919;
  width: ${p => p.width}px;
`
export const Selector = styled.div<{
  width: number
  left: number
}>`
  position: absolute;
  top: 4px;
  height: calc(100% - 8px);
  z-index: 0;
  background: #3c6;
  width: calc(${p => p.width}% - 8px);
  left: calc(${p => p.left}% + 4px);
  border-radius: 49px;
`
export const Button = styled.button<{ width: number }>`
  background: none;
  border: none;
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  width: ${p => p.width}%;
  color: #191919;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;

  &.selected {
    color: #fff;
  }

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }
`
