import { useReadContract } from 'wagmi'
import { Abi } from 'viem'

export const useGenericReadContract = (
  abi: Abi,
  address: `0x${string}`,
  functionName: string,
  args?: any[],
) => {
  const { data, status, queryKey, refetch } = useReadContract({
    abi,
    address,
    functionName,
    args,
  })

  return { data, status, queryKey, refetch }
}
