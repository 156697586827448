import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  width: 100%;
`
export const Texts = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 17px;
  }
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;
  max-width: 305px;

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
`
export const Subtitle = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.023%;
  max-width: 750px;
  
  span {
    font-weight: 500;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
  
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
`
