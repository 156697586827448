import UtilitiesFarming from '../Assets/UtilitiesFarming'
import UtilitiesLabs from '../Assets/UtilitiesLabs'
import UtilitiesLaunchpad from '../Assets/UtilitiesLaunchpad'
import UtilitiesStore from '../Assets/UtilitiesStore'
import UtilitiesMMaS from '../Assets/UtilitiesMMaS'
export const collapses = [
  {
    id: 1,
    title: '<strong>Launchpad</strong> - Use tokens to get allocations',
    icon: UtilitiesLaunchpad,

    text: `In order to participate, user should lock his tokens for a period of time that depends
    on the allocation size, type and terms of the project.`,
  },
  {
    id: 2,
    title: '<strong>NFT Store </strong> - Buy stocks from fintech companies',
    icon: UtilitiesStore,
    text: `Users can get access to the leading tech private companies, e.g. Ledger, BitPay, ConsenSys, Kraken, Chainalysis and others. Each NFT has a cost that depends on
      the allocation volume and tier. NFTs are purchased with $MMPRO token. NFT Store
      has a liquidity pool with buyback function, that is formed from all NFTs purchases.`,
  },
  {
    id: 3,
    title: '<strong>MMaS</strong> - Get 10% discount on market making services',
    icon: UtilitiesMMaS,
    text: `Average price for 1 month for 1 exchange 1 trading pair services is $3000 paid in USD
      or USDT, BUSD. Payment in tokens provides a 10% discount on the service and would
      cost $2,700. Moreover MMPro provides trading terminals rental, which can be paid
      with MMPRO token as well.`,
  },
  {
    id: 4,
    title: '<strong>Farming</strong> - Farm MMPRO with high APR',
    icon: UtilitiesFarming,
    text: 'Farm <a href="https://farming.marketmaking.pro/" target="_blank" styles>BUSD</a> and <a href="https://farming.marketmaking.pro/" target="_blank">WBNB</a> with $MMPRO on 37.3% and 19.8% APR accordingly.',
  },
  {
    id: 5,
    title:
      '<strong>MMPro Labs </strong> - Join closed community of investors with insights and profit from NFT Store',
    icon: UtilitiesLabs,
    text: `Each member has a special LABS NFT which grants him access there.
      Current investors and community members can join our private discussions,
      analytical reports and allocations negotiations. <strong>5% of NFT sales inside the LABS </strong>
      community go for the MMPRO buyback. Labs NFT costs 200,000 $MMPRO.
      Starting from July 2023 there is an opportunity* to exchange NFT to a share option
      of Market Making Pro Group. The tokens will be subsequently burnt after the
      exchange.`,
  },
]
