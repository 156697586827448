import React from 'react'
import {
  Wrapper,
  CardsWrapper,
  Title,
  ContentWrapper,
  BgEllipseLeft,
  BgEllipseRight,
} from './styled'
import { howItWorkCards } from './data'
import StageCard from './StageCard'

const HowWork = () => {
  return (
    <Wrapper>
      <BgEllipseLeft />
      <BgEllipseRight />
      <div className={'_container'}>
        <ContentWrapper>
          <Title>How it works</Title>
          <CardsWrapper>
            {howItWorkCards.map((card, index) => (
              <StageCard
                key={card.id}
                id={card.id}
                text={card.text}
                icon={card.icon}
                index={index}
              />
            ))}
          </CardsWrapper>
        </ContentWrapper>
      </div>
    </Wrapper>
  )
}

export default HowWork
