import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 64px;
  min-height: 466px;
  width: 100%;
  border-radius: 20px;
  background: #f5f8fa;
  padding: 55px 45px;
  margin-top: 120px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 40px;
    margin-top: 90px;
    padding: 40px 30px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  z-index: 1;
`
export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`
export const Title = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
`
export const SubTitle = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`
export const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
`
export const ButtonText = styled.div`
  color: #fff;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 179%;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`
export const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  z-index: 1;
  width: 100%;
`
export const FaqText = styled.div`
  color: #181833;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
`
export const CirclesImage = styled.img`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  pointer-events: none;

  @media screen and (max-width: 1319px) {
    display: none;
  }
`
