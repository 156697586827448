import React from 'react'
import { Wrapper, TitleAndCollapseWrapper, Title } from './styled'
import Collapse from './Collapse'
import UtilitiesImg from './UtilitiesImg'

const Utilities = () => {
  return (
    <div className={'_container'}>
      <Wrapper>
        <TitleAndCollapseWrapper>
          <Title>MMPro Utilities</Title>
          <Collapse />
        </TitleAndCollapseWrapper>
        <UtilitiesImg />
      </Wrapper>
    </div>
  )
}

export default Utilities
