import UsdcIcon from '../widgets/Project/Assets/Usdc.png'
import { contractsAddresses } from './contractsAddresses'
import { UsdcAbi } from '../contracts/Usdc'
import UsdtIcon from '../widgets/Project/Assets/Usdt.png'
import { UsdtAbi } from '../contracts/Usdt'
import DaiIcon from '../widgets/Project/Assets/Dai.png'
import { DaiAbi } from '../contracts/Dai'

export const stableCoins = [
  {
    id: 1,
    name: 'USDC',
    img: UsdcIcon,
    contractAddress: contractsAddresses.usdc,
    abi: UsdcAbi,
  },
  {
    id: 2,
    name: 'USDT',
    img: UsdtIcon,
    contractAddress: contractsAddresses.usdt,
    abi: UsdtAbi,
  },
  {
    id: 3,
    name: 'DAI',
    img: DaiIcon,
    contractAddress: contractsAddresses.dai,
    abi: DaiAbi,
  },
]
