import React from 'react'
import Timer from '../Common/Timer'
import { Wrapper, Text, Heading } from './styled'
import { ProjectType } from '../../../../types/project'

type SubscriptionStartsInProps = {
  project: ProjectType
}

const SubscriptionStartsIn = ({ project }: SubscriptionStartsInProps) => {
  return (
    <Wrapper>
      <Heading>Subscription starts in</Heading>
      <Timer dateInSeconds={project.subStartTime} />
      <Text>Please wait for start allocation distribution.</Text>
    </Wrapper>
  )
}

export default SubscriptionStartsIn
