import { useAccount, useReadContract } from 'wagmi'
import { MainContractAbi } from '../contracts/MainContract'
import { contractsAddresses } from '../config/contractsAddresses'

export const useUserInfo = (id: number) => {
  const { address } = useAccount()
  const userProjectInfo = useReadContract({
    abi: MainContractAbi,
    address: contractsAddresses.main,
    functionName: 'userInfo',
    args: [address || '0x1', BigInt(id)],
  })

  const [totalDeposit, allocatedAmount, purchasedAmount, rewardDebt] =
    userProjectInfo.data || []

  return {
    totalDeposit,
    allocatedAmount,
    purchasedAmount,
    rewardDebt,
    response: userProjectInfo,
  }
}
