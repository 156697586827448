import React from 'react'
import {
  Wrapper,
  CompanyWrapper,
  CompanyName,
  CompanyTeam,
  CompanyTexts,
  MainText,
  QouteText,
  QuoteIcon,
} from './styled'
import { Review } from '../data'
import Quotes from '../Assets/Quotes'

type ReviewCard = {
  review: Review
}

const ReviewCard = ({ review }: ReviewCard) => {
  return (
    <Wrapper background={review.backgroundColor}>
      <QouteText color={review.textColor}>#QUOTE</QouteText>
      <QuoteIcon>
        <Quotes fill={review.quotesColor} />
      </QuoteIcon>
      <MainText color={review.textColor}>{review.mainText}</MainText>
      <CompanyWrapper>
        {review.icon()}
        <CompanyTexts>
          <CompanyName color={review.textColor}>{review.companyName}</CompanyName>
          <CompanyTeam color={review.textColor}>Team</CompanyTeam>
        </CompanyTexts>
      </CompanyWrapper>
    </Wrapper>
  )
}

export default ReviewCard
