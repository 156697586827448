import Buy from './Assets/Buy'
import Claim from './Assets/Claim'
import Connect from './Assets/Connect'
import Participation from './Assets/Participation'
import Shares from './Assets/Shares'
import Withdraw from './Assets/Withdraw'
import { ReactNode } from 'react'

export type HowItWorkCard = {
  id: number
  text: string
  icon: () => ReactNode
}
export const howItWorkCards: HowItWorkCard[] = [
  {
    id: 1,
    text: 'Buying MMPro tokens',
    icon: Buy,
  },
  {
    id: 2,
    text: 'Connect the wallet to the MMPro Pad',
    icon: Connect,
  },
  {
    id: 3,
    text: 'Participation in allocation',
    icon: Participation,
  },
  {
    id: 4,
    text: 'Buying tokens or shares',
    icon: Shares,
  },
  {
    id: 5,
    text: 'Claiming',
    icon: Claim,
  },
  {
    id: 6,
    text: 'LaunchpadFinished MMPRO tokens',
    icon: Withdraw,
  },
]
