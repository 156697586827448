import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { projects } from '../../config/projects'
import Card from '../../components/Projects/Project'
import Info from './Components/Common/Info'
import {
  Wrapper,
  Container,
  ContentWrapper,
  ReturnBackButton,
  ReturnBackButtonText,
} from './styled'
import { IRouteParams } from '../../types/route'
import ProjectStatusRender from './Components/Common/ProjectStatusRender'
import { usePoolInfo } from '../../hooks/usePoolInfo'
import { useReadContract } from 'wagmi'
import { MainContractAbi } from '../../contracts/MainContract'
import { contractsAddresses } from '../../config/contractsAddresses'
import BackArrowIcon from './Assets/BackArrow'
import { useNavigate } from 'react-router-dom'

const headerLinks = [
  {
    title: 'About MMPro',
    to: 'about',
  },
  {
    title: 'Projects',
    to: 'projects',
  },
  {
    title: 'Farming',
    to: 'https://farming.marketmaking.pro/',
  },
]

const footerLinks = {
  'Main': [
    {
      title: 'Apply for IDO',
      href: 'https://forms.gle/jZeXrnRW8VjKV7VDA',
    },
    {
      title: 'Referral Program',
      href: 'https://t.me/MMPROWL_bot',
    },
    {
      title: 'Farming',
      href: 'https://farming.marketmaking.pro/',
    },
  ],
  'Token Info': [
    {
      title: 'CoinGecko',
      href: 'https://www.coingecko.com/en/coins/market-making-pro',
    },
    {
      title: 'CoinMarketCap',
      href: 'https://coinmarketcap.com/currencies/mmpro-token/',
    },
    {
      title: 'PancakeSwap',
      href: 'https://pancakeswap.finance/swap?outputCurrency=0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70',
    },
    {
      title: 'Certik',
      href: 'https://skynet.certik.com/projects/mmpro-token',
    },
  ],
}
const Project = () => {
  const { id } = useParams<keyof IRouteParams>() as IRouteParams
  const navigate = useNavigate()
  const { lockupEnd, purchaseEnd, stakingEnd, startTimestamp, ITokenPrice } = usePoolInfo(
    Number(id),
  )

  const { data: isClaimAllowed } = useReadContract({
    abi: MainContractAbi,
    address: contractsAddresses.main,
    functionName: 'pickUpTokensAllowed',
    args: [BigInt(id)],
  })

  const project = useMemo(() => {
    const foundProject = projects.find(project => project.id === +id)
    return foundProject
      ? {
          ...foundProject,
          subStartTime: Number(startTimestamp),
          subEndTime: Number(stakingEnd),
          saleOpen: Number(stakingEnd),
          saleClose: Number(purchaseEnd),
          claim: Number(lockupEnd),
          isClaimable: isClaimAllowed || false,
          price: Number(ITokenPrice) / 100000,
        }
      : null
  }, [id, lockupEnd, purchaseEnd, stakingEnd, startTimestamp])

  const onBackClick = () => {
    navigate('/professional', { replace: true })
  }

  return (
    <Wrapper>
      <Header links={headerLinks} />
      <div className={'_container'}>
        <ContentWrapper>
          <ReturnBackButton onClick={onBackClick}>
            <BackArrowIcon />
            <ReturnBackButtonText>Live project</ReturnBackButtonText>
          </ReturnBackButton>
          <Container>
            {project && (
              <>
                <Card key={1} project={project} />
                <ProjectStatusRender project={project} />
                <Info project={project} />
              </>
            )}
          </Container>
        </ContentWrapper>
      </div>
      <Footer links={footerLinks} />
    </Wrapper>
  )
}

export default Project
